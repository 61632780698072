import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MachineModel, ProjectModel, SiteModel } from '@types'

import { State } from './type'

const initialState: State = {}

export const slice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    clean: () => initialState,
    setProject: (state, action: PayloadAction<ProjectModel | undefined>) => {
      state.project = action.payload
    },
    setSite: (state, action: PayloadAction<SiteModel | undefined>) => {
      state.site = action.payload
    },
    setMachine: (state, action: PayloadAction<MachineModel | undefined>) => {
      state.machine = action.payload
    },
    setRole: (state, action: PayloadAction<string | undefined>) => {
      state.role = action.payload
    }
  }
})

// > Export
// * Action
export const actions = {
  ...slice.actions
}
// * Reducer
export const { reducer } = slice
