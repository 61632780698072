import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const globalStyle = (theme: Theme) => css`
  .giraffe-brush-selection {
    background-color: ${theme.palette.primary.main} !important;
  }

  .MuiDataGrid-root.MuiDataGrid-root--densityCompact {
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaders {
      height: 50px !important;
      line-height: 50px !important;
    }
  }

  .MuiChipsInput-TextField {
    label.Mui-focused:not(.Mui-error) {
      color: ${theme.palette.primary.main} !important;
    }
    label.Mui-error {
      color: ${theme.palette.error.main} !important;
    }

    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(${theme.palette.customColors.main}, 0.32);
    }
    .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.primary.main} !important;
    }
    .Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.error.main} !important;
    }
  }

  .MuiAlert-filledSuccess {
    background-color: ${theme.palette.primary.main} !important;
  }

  .react-grid-item {
    &.resizing .react-resizable-handle,
    &.react-draggable-dragging .react-resizable-handle {
      opacity: 1;
      border-right-color: ${theme.palette.primary.main};
      border-bottom-color: ${theme.palette.primary.main};
    }

    &.resizing,
    &.react-draggable-dragging {
      border: solid 2px ${theme.palette.primary.main};
    }

    &.react-grid-placeholder {
      background: ${theme.palette.primary.dark};
    }

    .react-resizable-handle {
      position: absolute;
      background-image: none;
      cursor: nwse-resize;
      border: 15px solid transparent;
      border-right-color: ${theme.palette.primary.main};
      border-bottom-color: ${theme.palette.primary.main};
      border-bottom-right-radius: 4px;
      z-index: 5;
      transition: border-color 0.25s ease, opacity 0.25s ease;
      width: 30px !important;
      height: 30px !important;
      bottom: 0 !important;
      right: 0 !important;
      opacity: 0;

      &:hover {
        opacity: 1;
        border-right-color: ${theme.palette.primary.main};
        border-bottom-color: ${theme.palette.primary.main};
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 2px !important;
        border-radius: 1px;
        background-color: #07070e;
        transition: background-color 0.25s ease;
        transform: translate(-50%, -50%) rotate(-45deg);
        border: 0;
      }

      &::before {
        width: 12px !important;
        top: 7px !important;
        left: 7px !important;
      }

      &:after {
        width: 20px !important;
        top: 4px !important;
        left: 4px !important;
      }
    }
  }
`
