import { useDispatch } from 'react-redux'

import { userActions } from '@store'
import { TokenModel } from '@types'
import { parseJwt } from '@utils'
import api from '@api'
import { storage } from '@defines'

import { useGetConfig } from './useGetConfig'

export const useDecodeUser = () => {
  const dispatch = useDispatch()
  const { get } = useGetConfig()

  const decode = () => {
    // eslint-disable-next-line
    const accessToken = api.caller.defaults.headers.common['Authorization'] as string
    if (!accessToken) return
    const user: TokenModel & { exp: number } = parseJwt(accessToken)
    if (Date.now() < user.exp * 1000) {
      localStorage.setItem(storage.USER_EMAIL, user.email)

      dispatch(userActions.setUser(user))
      get()
    } else {
      api.removeToken()
    }
  }

  return { decode }
}
