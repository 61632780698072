import { STATUS } from './app'

export const STATUS_BG_COLOR: Record<STATUS, string> = {
  [STATUS.MAINTENANCE]: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #26C6F9',
  [STATUS.HEALTHY]: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #4CAF50',
  [STATUS.WARNING]: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #FDB528',
  [STATUS.ALARM]: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #FF4D49',
  [STATUS.DISCONNECT]: '#fff'
}

export const STATUS_COLOR: Record<STATUS, string> = {
  [STATUS.MAINTENANCE]: '#26C6F9',
  [STATUS.HEALTHY]: '#4CAF50',
  [STATUS.WARNING]: '#FDB528',
  [STATUS.ALARM]: '#B00020',
  [STATUS.DISCONNECT]: 'rgba(0, 0, 0, 0.6)'
}

export const GAUGE_COLOR: Record<STATUS, string> = {
  [STATUS.MAINTENANCE]: '#26C6F9',
  [STATUS.HEALTHY]: '#4CAF50',
  [STATUS.WARNING]: '#FDB528',
  [STATUS.ALARM]: '#FF4D49',
  [STATUS.DISCONNECT]: 'rgba(0, 0, 0, 0.6)'
}
