import moment from 'moment'

import { DEFAULT_QUERIES } from '@defines'
import { PaginationQueries, PaginationSortItem } from '@types'

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const objectToFormData = (obj: Record<string, any>) => {
  const formData = new FormData()
  Object.keys(obj).forEach(k => formData.append(k, obj[k]))
  return formData
}

export const chartArrayDataToCSVForm = (data: string[], fieldFilter?: string[]) => {
  const fields: string[] = []
  const groups: string[] = ['false', 'false', 'true', 'true', 'false', 'false', 'true', 'true']
  const datatype: string[] = [
    'string',
    'long',
    'dateTime:RFC3339',
    'dateTime:RFC3339',
    'dateTime:RFC3339',
    'double',
    'string',
    'string'
  ]
  const tags: string[] = ['', 'result', 'table', '_start', '_stop', '_time', '_value', '_field', '_measurement']
  const result = data?.reduce((r, o) => {
    if (!o) return r

    const arr: string[] = new Array(tags.length).fill('')
    let flag = false
    o.split(',').forEach(oo => {
      // eslint-disable-next-line
      const [_, field, value] = oo.match(/(.+?):(.+)/) as any

      if (tags.every(ooo => ooo !== field)) {
        tags.push(field)
        arr.push('')
        groups.push('true')
        datatype.push('string')
      }

      if (field === 'table') arr[2] = value
      else if (field === '_start') arr[3] = new Date(moment(value).toISOString()).toISOString()
      else if (field === '_stop') arr[4] = new Date(moment(value).toISOString()).toISOString()
      else if (field === '_time') arr[5] = new Date(moment(value).toISOString()).toISOString()
      else if (field === '_value') arr[6] = value
      else if (field === '_field') {
        if (fieldFilter?.includes(value) || fieldFilter?.includes('All') || !fieldFilter) flag = true

        fields.push(value)
        arr[7] = value
      } else if (field === '_measurement') arr[8] = value
      else if (field !== 'result') {
        const i = tags.findIndex(ooo => ooo === field)
        if (i !== -1) {
          arr[i] = value
        }
      }
    })

    if (flag && arr[6] !== '<nil>') r.push(arr)
    return r
  }, [] as string[][])
  const csvData = `#group,${groups.join(',')}
#datatype,${datatype.join(',')}
#default,mean,,,,,,,
${tags.join(',')}
${result.map(o => o.join(',')).join('\n')}`
  return {
    data: csvData,
    fields: Array.from(new Set(fields))
  }
}

export const queriesToString = (options?: { defaultQueries?: boolean; currentQueries?: PaginationQueries }) => {
  const search = new URLSearchParams()
  const queries = options?.defaultQueries || !options?.currentQueries ? DEFAULT_QUERIES : options.currentQueries
  Object.keys(queries).forEach(o => {
    const value = (queries as any)[o]
    if (o === 'sort') {
      if (typeof value === 'object' && value.length) {
        const sortItems = value as PaginationSortItem[]
        const sortString = [...sortItems].map(oo => `${oo.field},${oo.sort}`).join('|')
        search.append('sort', sortString)
      }
    } else if (o === 'keyword') {
      if (value) search.append('keyword', value.trim().toLowerCase())
    } else if (o === 'page') {
      search.append('page', value + 1)
    } else search.append(o, value)
  })
  return search
}
