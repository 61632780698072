import { VerticalNavItemsType } from '@core/types'
import { navigationCoreVertical } from '@core/layouts/navigation'
import { routers } from '@defines'

const items: VerticalNavItemsType = [
  {
    title: 'Settings',
    icon: 'ep:setting',
    path: routers.INFO
  }
]

export const VerticalNavItems = (isDevMode: boolean): VerticalNavItemsType => [
  ...items,
  ...(isDevMode ? navigationCoreVertical : [])
]
