import { useDispatch } from 'react-redux'

import api from '@api'
import { configsActions } from '@store'

export const useGetConfig = () => {
  const dispatch = useDispatch()

  const get = async () => {
    const response = await api.getRoles()
    dispatch(configsActions.setRoles(response.data))
  }

  return { get }
}
