import { navigationCoreHorizontal } from '@core/layouts/navigation'
import { HorizontalNavItemsType } from '@core/types'
import { routers } from '@defines'

const items: HorizontalNavItemsType = [
  {
    title: 'Settings',
    icon: 'fluent:home-28-regular',
    path: routers.PROFILE
  }
]

export const HorizontalNavItems = (isDevMode: boolean): HorizontalNavItemsType => [
  ...items,
  ...(isDevMode ? navigationCoreHorizontal : [])
]
