type TokenData = { token: string; save: boolean }

class ChannelToken {
  public channel!: BroadcastChannel

  static instance?: ChannelToken

  private data?: TokenData

  private onUpdate?: (data?: TokenData) => void

  private onDelete?: () => void

  static getInstance() {
    if (!this.instance) this.instance = new ChannelToken()
    return this.instance
  }

  constructor() {
    this.init()
  }

  private checkSync = () => {
    if (!this.data) this.channel.postMessage({ message: 'request' })
  }

  private init() {
    const channel = new BroadcastChannel('token')
    this.channel = channel

    channel.addEventListener('message', ev => {
      if (ev.data.message === 'update') {
        const data: TokenData = ev.data.data
        this.data = data
        if (this.onUpdate) this.onUpdate(data)
      } else if (ev.data.message === 'delete') {
        this.data = undefined
        if (this.onDelete) this.onDelete()
      } else if (ev.data.message === 'request') {
        if (this.data) this.pushData(this.data)
      }
    })

    this.checkSync()
  }

  public addEventListenner(event: 'update' | 'delete', callback: typeof this.onUpdate) {
    if (event === 'update') {
      this.onUpdate = callback
      if (this.data && callback) callback(this.data)
    } else if (event === 'delete') {
      this.onDelete = callback
    }
  }

  public pushData(data?: TokenData) {
    this.data = data
    this.channel.postMessage({ message: 'update', data: data })
  }

  public deleteData() {
    this.channel.postMessage({ message: 'delete' })
  }
}

export const channelToken = ChannelToken.getInstance()
