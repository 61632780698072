import { forwardRef, Ref, useEffect, useRef } from 'react'
// @mui
import { Box, FormControl, OutlinedInput, InputAdornment, Theme, useTheme, Typography, Tooltip } from '@mui/material'
import styled from '@emotion/styled'

import { Icon } from '@core/components/custom'
import { useAutoComplete } from '@hooks'
import { Loading } from '@components'
import { Pagination } from '@types'

const Wrapper = styled(Box)<{ theme: Theme; loading: boolean }>`
  overflow-y: scroll;
  width: 300px;
  min-height: ${({ loading }) => (loading ? '450' : '0')}px;
  max-height: 450px;
  position: absolute;
  background-color: ${({ theme }) => `${theme.palette.customColors.bodyBg}`};
  z-index: 100;
  border-radius: 10px;
  box-shadow: ${({ theme }) => `rgba(${theme.palette.customColors.main},0.2) 0px 8px 24px`};
  padding: 10px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

export type DropdownProps<RecordType> = {
  onClick: (item: RecordType) => void
  fetch: (search: URLSearchParams) => Promise<Pagination<RecordType> | undefined>
  getKey: (item: RecordType) => string
  getLabel: (item: RecordType) => string
}

export const Dropdown = forwardRef(
  <RecordType extends object>(props: DropdownProps<RecordType>, ref?: Ref<HTMLDivElement>) => {
    const localRef = useRef(null)
    const theme = useTheme()
    const hook = useAutoComplete({
      fetch: props.fetch,
      transformItemsToOptions: (i: any) => i.id
    })

    useEffect(() => {
      hook.onOpen()
    }, [])

    return (
      <Wrapper ref={ref || localRef} theme={theme} loading={hook.loading} onScroll={hook.onScroll}>
        <Box sx={{ position: 'relative' }}>
          <FormControl fullWidth sx={{ marginBottom: '12px', position: 'absolute', top: 0 }}>
            <OutlinedInput
              sx={{ height: '45px' }}
              onChange={e => hook.onChangeInput(e.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  <Icon icon='material-symbols:search' />
                </InputAdornment>
              }
            />
          </FormControl>
          {hook.data.items && !hook.loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '55px' }}>
              {hook.data.items.map((item, index) => {
                return (
                  <Tooltip key={index} title={props.getLabel(item)} placement={'right-start'}>
                    <Box
                      key={props.getKey(item)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        height: '40px',
                        cursor: 'pointer',

                        '&:hover': {
                          backgroundColor: themeStyle => themeStyle.palette.customColors.trackBg
                        }
                      }}
                      onClick={() => props.onClick(item)}
                    >
                      <Typography
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
                      >
                        {props.getLabel(item)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )
              })}
            </Box>
          ) : (
            <Box sx={{ paddingTop: '55px' }} />
          )}
          {hook.loading && (
            <Box sx={{ paddingTop: '55px' }}>
              <Loading />
            </Box>
          )}
        </Box>
      </Wrapper>
    )
  }
)
