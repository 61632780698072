import { FormEventHandler, Fragment } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { useTranslation } from 'react-i18next'

type Props = {
  title?: string
  description: string
  close: () => void
  cancel?: () => Promise<void> | void
  ok?: () => Promise<void> | void
  cancelProps?: ButtonProps
  okProps?: LoadingButtonProps
  confirmLoading?: boolean
} & DialogProps

export const DialogConfirmation: React.FC<Props> = ({ title, ...props }) => {
  const { t } = useTranslation()

  const submit = () => {
    if (props.ok) props.ok()
    else props.close()
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    submit()
  }

  return (
    <Fragment>
      <Dialog
        {...props}
        onClose={props.close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        onKeyUp={e => {
          const ENTER = 13
          if (e.keyCode === ENTER) submit()
        }}
      >
        <form action='/' name='confirm' noValidate autoComplete='off' onSubmit={onSubmit}>
          <DialogTitle id='alert-dialog-title'>{title || t('common.notice')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{t(props.description)}</DialogContentText>
          </DialogContent>
          <DialogActions className='dialog-actions-dense'>
            <Button {...props.cancelProps} onClick={props.cancel || props.close}>
              {t('cancel')}
            </Button>
            <LoadingButton {...props.okProps} type='submit' loading={props.confirmLoading}>
              {props.okProps?.children || t('ok')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  )
}
