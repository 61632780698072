import { Alert, IconButton, Snackbar } from '@mui/material'
import { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { appActions, appSelector } from '@store'
import { Icon } from '@core/components/custom'

export const useNotice = () => {
  const dispatch = useDispatch<AppDispatch>()
  const notice = useSelector(appSelector.selectNotice)

  const releaseNotice = () => dispatch(appActions.setNotice())

  const renderAction = () => (
    <Fragment>
      <IconButton size='small' aria-label='close' color='inherit' onClick={releaseNotice}>
        <Icon icon='mdi:close' fontSize={20} />
      </IconButton>
    </Fragment>
  )

  const render = () => (
    <Snackbar
      open={Boolean(notice)}
      onClose={releaseNotice}
      message={notice?.message}
      autoHideDuration={notice?.duration || 3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={renderAction()}
      children={
        notice?.severity ? (
          <Alert
            elevation={3}
            variant='filled'
            onClose={releaseNotice}
            severity={notice.severity}
            action={renderAction()}
          >
            {notice?.message}
          </Alert>
        ) : undefined
      }
    />
  )

  return { render }
}
