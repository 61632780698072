import { Plot } from '@influxdata/giraffe'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

import { Thresholds } from '@types'
import { useGaugeChart } from '@hooks'
import { STATUS_BG_COLOR, STATUS_COLOR, STATUS } from '@defines'

import { SiteNameStyle, BoxStyle, MachineNumberStyle, StatusStyle } from './styles'

type Props = {
  name: string
  machineState: STATUS
  threshold: Thresholds
  measurements?: string[]
}

export const MachineBox: React.FC<Props> = props => {
  const { name, measurements, machineState, threshold: thresholds } = props

  const hook = useGaugeChart({ chartDataArr: measurements, thresholds })

  const statusFilter = measurements ? machineState : STATUS.DISCONNECT

  return (
    <BoxStyle
      sx={{
        background: STATUS_BG_COLOR[statusFilter]
      }}
    >
      <Tooltip title={name}>
        <SiteNameStyle>{name}</SiteNameStyle>
      </Tooltip>
      <MachineNumberStyle>Anomaly score: {hook.score} / 5.00</MachineNumberStyle>
      <StatusStyle sx={{ color: STATUS_COLOR[statusFilter] }}>{statusFilter}</StatusStyle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          '& .giraffe-layers': { color: '#000' }
        }}
      >
        <Plot config={hook.configGauge} />
      </Box>
    </BoxStyle>
  )
}
