import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DataGridProps, GridSortModel } from '@mui/x-data-grid'

import { Pagination, PaginationQueries } from '@types'
import { appActions } from '@store'
import { DEFAULT_QUERIES } from '@defines'
import { queriesToString } from '@utils'

import { useDebounce } from './useDebounce'

type Params<RecordType> = Omit<DataGridProps, 'rows' | 'columns'> & {
  fetch: (search: URLSearchParams) => Promise<Pagination<RecordType> | undefined>
  remove: (id: string) => Promise<unknown>
}

type Return = Omit<DataGridProps, 'columns'> & {
  fetch: (options?: { defaultQueries?: boolean }) => Promise<Pagination<unknown> | undefined>
  remove: (id: string) => Promise<unknown>
  resetQueries: () => void
  onSearch: (value: string) => void
  queries: PaginationQueries
  isShowTable: boolean
  searchValue: string
}

export const useTable = <RecordType extends object>(params: Params<RecordType>): Return => {
  const dispatch = useDispatch<AppDispatch>()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Pagination<RecordType>>({
    items: null,
    pagination: { totalPages: 1, currentPage: 1, totalRecords: 1 }
  })
  const [isHadData, setIsHadData] = useState(false)
  const [queries, setQueries] = useState<PaginationQueries>(Object.assign({}, DEFAULT_QUERIES))
  const [searchValue, setSearchValue] = useState('')
  const refQueries = useRef<PaginationQueries>(queries)
  const { debounce } = useDebounce()
  const { debounce: debounceSearch } = useDebounce()

  const updateQueries = (q: Partial<PaginationQueries>) => {
    const nq: Partial<PaginationQueries> = {}
    if (Object.keys(q).includes('page') && typeof q.page === 'number') nq.page = q.page
    if (Object.keys(q).includes('pageSize') && q.pageSize) nq.pageSize = q.pageSize
    if (Object.keys(q).includes('keyword')) nq.keyword = q.keyword
    if (Object.keys(q).includes('sort')) nq.sort = q.sort
    if (Object.keys(q).includes('filter')) nq.filter = q.filter

    setQueries(state => {
      const mq = {
        ...state,
        ...nq
      }
      refQueries.current = mq
      return mq
    })
  }

  const resetQueries = () => {
    setQueries(Object.assign({}, DEFAULT_QUERIES))
    refQueries.current = Object.assign({}, DEFAULT_QUERIES)
    setSearchValue('')
  }

  const onPageChange = (page: number) => updateQueries({ page })

  const onPageSizeChange = (value: number) => updateQueries({ page: 0, pageSize: value })

  const onSearch = (value: string) => {
    setSearchValue(value)
    debounceSearch(async () => {
      updateQueries({ keyword: value, page: 0 })
    })
  }

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) =>
      updateQueries({ sort: sortModel.map(o => ({ ...o, sort: o.sort === 'asc' ? 'ascending' : 'descending' })) }),
    []
  )

  const fetchHandle = async (options?: { defaultQueries?: boolean }) => {
    try {
      setLoading(true)
      const response = await params.fetch(
        queriesToString({ defaultQueries: options?.defaultQueries, currentQueries: refQueries.current })
      )
      if (response) {
        setData(response)
        if (
          refQueries.current.page === Object.assign({}, DEFAULT_QUERIES).page &&
          !refQueries.current.keyword &&
          !refQueries.current.filter?.length &&
          !response.items?.length &&
          isHadData
        ) {
          setIsHadData(false)
        } else if (response.items?.length && !isHadData) setIsHadData(true)
      }
      return response
    } catch (error) {
      dispatch(
        appActions.setNotice({
          message: error instanceof Error ? error.message : typeof error === 'string' ? error : 'error.unknow',
          severity: 'error'
        })
      )
    } finally {
      setLoading(false)
    }
  }

  const fetch = async (options?: { defaultQueries?: boolean }) => {
    return debounce(async () => {
      const response = fetchHandle(options)
      return response
    })
  }

  const remove = async (id: string) => {
    await params.remove(id)
    await fetch()
  }

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    if (!location.search) return
    const qparse = JSON.parse(
      '{"' + decodeURI(location.search.replace('?', '').replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
    )
    if (qparse.sort) {
      qparse.sort = qparse.sort.split('%7C').map((o: string) => {
        const [field, sort] = o.split('%2C')
        return { field, sort }
      })
    } else if (qparse.keyword) {
      const search = new URLSearchParams(`keyword=${qparse.keyword}`)
      qparse.keyword = search.get('keyword')
      setSearchValue(qparse.keyword)
    } else if (qparse.page) {
      qparse.page = +qparse.page - 1
    } else if (qparse.pageSize) {
      qparse.pageSize = +qparse.pageSize
    }
    updateQueries(qparse)
  }, [])

  useEffect(() => {
    const url = `${window.location.pathname}?${queriesToString({ currentQueries: refQueries.current })}`
    window.history.replaceState({}, '', url)
  }, [queries])

  useEffect(() => {
    fetch()
  }, [queries])

  return {
    ...params,
    fetch,
    remove,
    queries,
    searchValue,
    resetQueries,
    onSearch,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    loading,
    pagination: true,
    initialState: {
      pagination: {
        page: queries.page
      },
      sorting: {
        sortModel: [
          {
            field: 'updatedAt',
            sort: 'desc'
          }
        ]
      }
    },
    sortingMode: 'server',
    paginationMode: 'server',
    pageSize: +queries.pageSize,
    rows: data.items || [],
    rowsPerPageOptions: [10, 20, 50],
    rowCount: data.pagination.totalRecords,
    page: data.pagination.currentPage > 0 ? data.pagination.currentPage - 1 : 0,
    disableColumnMenu: true,
    disableColumnFilter: true,
    isShowTable:
      isHadData ||
      !(
        queries.page === 0 &&
        !Boolean(data.items?.length) &&
        !Boolean(queries.keyword) &&
        !Boolean(queries.filter?.length)
      )
  }
}
