import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { userSelector } from '@store'
import { routers, routersGuard } from '@defines'

export type Options = { checkVerify?: boolean; autoRedirect?: boolean }

type Props = PropsWithChildren & {
  options?: Options
}

const Container: React.FC<Props> = ({ children, options }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const userData = useSelector(userSelector.selectData)

  useEffect(() => {
    if (userData) {
      const { isVerified } = userData
      const search = new URLSearchParams(location.search)
      const redirect = search.get('redirect')

      if (options?.autoRedirect !== false) {
        if (!isVerified && options?.checkVerify !== false && !redirect?.includes(routers.VERIFY)) {
          navigate(`${routers.VERIFY}${search.toString() ? `?${search.toString()}` : ''}`)
        } else if (redirect) {
          navigate(redirect)
        } else if (routersGuard.every(o => !location.pathname.replace(/\//, '').includes(o.replace(/\//, '')))) {
          navigate(routers.PROJECT)
        }
      }
    }
  }, [userData])

  return <>{children}</>
}

export const useRouteRender = (node: ReactNode, options?: Options) => {
  return <Container options={options}>{node}</Container>
}
