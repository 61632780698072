import { ReactElement, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Typography, BreadcrumbsProps, Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import styled from '@emotion/styled'

import { Icon } from '@core/components/custom'
import { Dropdown } from '@components'

// ----------------------------------------------------------------------

type LinkItemProps = {
  link: TLink
  fetch?: any
}

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const LinkItem = ({ link }: LinkItemProps) => {
  const [openSearchBox, setOpenSearchBox] = useState<boolean>(false)
  // const { href, name, to, fetch, type } = link
  const { href, name, fetch, getKey, getLabel, onClick } = link

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={() => setOpenSearchBox(true)}
      onMouseLeave={() => setOpenSearchBox(false)}
    >
      <Link
        key={name}
        variant='body2'
        component={RouterLink}
        to={href || '#'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          color: themeStyle => `rgba(${themeStyle.palette.customColors.main},0.38)`,
          '& > div': { display: 'inherit' }
        }}
      >
        <Text>{name}</Text>
        <Box sx={{ marginLeft: '15px', '& svg': { width: 14, height: 14 } }}>
          <Icon icon='material-symbols:arrow-forward-ios' />
        </Box>
      </Link>
      {openSearchBox && fetch && getKey && getLabel && onClick && (
        <Dropdown fetch={fetch} getKey={getKey} getLabel={getLabel} onClick={onClick} />
      )}
      {/* {openSearchBox && fetch && <SearchBox to={to} type={type as string} fetch={fetch} />} */}
    </Box>
  )
}

// ----------------------------------------------------------------------

type TLink = {
  href?: string
  name: string
  icon?: ReactElement
  // to?: string
  // type?: string
} & Partial<any>

export type Props = BreadcrumbsProps & {
  links: TLink[]
  activeLast?: boolean
}

const Breadcrumbs = ({ links, activeLast = false, ...other }: Props) => {
  const currentLink = links[links.length - 1].name

  const listDefault = links.map(link => <LinkItem key={link.name} link={link} />)

  const listActiveLast = links.map(link => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: themeStyle => themeStyle.palette.customColors.main
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ))

  return (
    <MUIBreadcrumbs
      separator={<Box component='span' sx={{ borderRadius: '50%', bgcolor: 'text.disabled', display: 'none' }} />}
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
