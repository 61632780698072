import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'

import api from '@api'
import { Dropdown } from '@components'
import { Icon } from '@core/components/custom'
import { Settings } from '@core/types'
import { routers } from '@defines'
import { useComponentVisible, useController } from '@hooks'

const ProjectDropdown = ({ settings }: { settings: Settings }) => {
  const { t } = useTranslation()
  const { ref, showDialogAction, setShowDialogAction } = useComponentVisible(false)
  const navigate = useNavigate()
  const { controller } = useController()
  const refTimeOutMouseLeave = useRef<NodeJS.Timeout>()

  const fetchProjectList = async (search: URLSearchParams) =>
    controller(async () => {
      const response = await api.getProjects(search)
      return response.data
    })

  const releaseTimeout = () => {
    if (refTimeOutMouseLeave.current) {
      clearTimeout(refTimeOutMouseLeave.current)
      refTimeOutMouseLeave.current = undefined
    }
  }

  const onMouseEnter = () => {
    releaseTimeout()
    setShowDialogAction(true)
  }

  const onMouseLeave = () => {
    releaseTimeout()
    refTimeOutMouseLeave.current = setTimeout(() => {
      setShowDialogAction(false)
      releaseTimeout()
    }, 200)
  }

  return (
    <Box
      sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '11px' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Typography
        sx={{
          fontWeight: '510',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: settings.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
          cursor: 'pointer'
        }}
        onClick={() => {
          navigate(routers.PROJECT_LIST)
          setShowDialogAction(false)
        }}
      >
        {t('Projects')}
      </Typography>
      <Icon
        icon={`material-symbols:keyboard-arrow-${showDialogAction ? 'down' : 'up'}-rounded`}
        style={{
          color: settings.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
          cursor: 'pointer'
        }}
        onClick={() => setShowDialogAction(!showDialogAction)}
        width={22}
      />
      {showDialogAction && (
        <Box sx={{ position: 'absolute', top: '30px' }}>
          <Dropdown
            ref={ref}
            fetch={fetchProjectList}
            getKey={(item: any) => item.id}
            getLabel={(item: any) => item.name}
            onClick={(item: any) => {
              navigate(routers.PROJECT_OVERVIEW.replace(':idProject', item.id))
              setShowDialogAction(false)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default ProjectDropdown
