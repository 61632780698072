import { createSelector } from '@reduxjs/toolkit'

const selectSelf = (state: RootState) => state.workspace

export const selector = {
  selectProject: createSelector(selectSelf, state => state.project),
  selectSite: createSelector(selectSelf, state => state.site),
  selectMachine: createSelector(selectSelf, state => state.machine),
  selectRole: createSelector(selectSelf, state => state.role)
}
