import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import {
  Response,
  SignUpResponse,
  SignUpDto,
  SignInDto,
  SignInResponse,
  ForgotPasswordDto,
  VerifyDto,
  RenewPasswordDto,
  ChangePasswordDto,
  ProfileResponse,
  CreateProjectDto,
  UpdateProjectDto,
  Pagination,
  ProjectModel,
  MeasurementModel,
  CreateMeasurementDto,
  UpdateMeasurementDto,
  UploadMeasurementDto,
  CreateSiteDto,
  CreateMachineDto,
  CreateMemberDto,
  UpdateMemberDto,
  MemberModel,
  CreateModelDto,
  SetMachineThresholdDto,
  ResolveMachineDto
} from '@types'
import { RESPONSE_CODE, storage } from '@defines'
import { channelToken } from '@broadcast'

import { objectToFormData } from './utils/common'

class Api {
  public caller!: AxiosInstance

  static instance?: Api

  static getInstance() {
    if (!this.instance) this.instance = new Api()
    return this.instance
  }

  constructor() {
    this.init()
  }

  private init() {
    this.caller = axios.create()

    // - Token
    const token = localStorage.getItem(storage.TOKEN) || sessionStorage.getItem(storage.TOKEN)
    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`
    // - Config
    // this.caller.defaults.baseURL = process.env.REACT_APP_API_URL
    this.caller.defaults.timeout = 1000 * 60 * 5
  }

  //> Handle
  public getAuthorizationToken = () => this.caller.defaults.headers.common.Authorization

  public isAuthorization = () => Boolean(this.getAuthorizationToken())

  public setToken(token: string, options?: { saveToken?: boolean; pushChannel?: boolean }) {
    if (options?.pushChannel !== false) channelToken.pushData({ token, save: Boolean(options?.saveToken) })

    if (options?.saveToken) localStorage.setItem(storage.TOKEN, token)
    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  public removeToken() {
    localStorage.removeItem(storage.TOKEN)
    // eslint-disable-next-line
    this.caller.defaults.headers.common['Authorization'] = ''
  }

  // > Controller
  private controller: <T = any>(handle: () => Promise<AxiosResponse<Response<T>>>) => Promise<Response<T>> =
    async handle => {
      try {
        const response = await handle()
        return response.data
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error', (error as any)?.response?.data?.resCode)

        if (error instanceof AxiosError) {
          const { response } = error
          const status: number | undefined = response?.status
          const message: RESPONSE_CODE = response?.data?.message
          // eslint-disable-next-line
          console.log('Axios error', status, message)
        }
        throw error
      }
    }

  // > Request
  private get<T = any, D = any>(url: string, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[GET]', url)
    return this.controller(() => this.caller.get(url, apiConfig))
  }

  private delete<T = any, D = any>(url: string, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[DELETE]', url)
    return this.controller(() => this.caller.delete(url, apiConfig))
  }

  private post<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[POST]', url)
    return this.controller(() => this.caller.post(url, data, apiConfig))
  }

  private put<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[PUT]', url)
    return this.controller(() => this.caller.put(url, data, apiConfig))
  }

  private patch<T = any, D = any>(url: string, data?: D, apiConfig?: AxiosRequestConfig<D>): Promise<Response<T>> {
    // eslint-disable-next-line
    console.log('[PATCH]', url)
    return this.controller(() => this.caller.patch(url, data, apiConfig))
  }

  // > API
  // - User
  public signUp = (body: SignUpDto) => this.post<SignUpResponse>(`${process.env.REACT_APP_API_URL_USER}/signup`, body)

  public signIn = (body: SignInDto) => this.post<SignInResponse>(`${process.env.REACT_APP_API_URL_USER}/signin`, body)

  public verify = (params: VerifyDto) =>
    this.get<SignInResponse>(
      `${process.env.REACT_APP_API_URL_USER}/verify?verificationCode=${params.verificationCode}&email=${params.email}`
    )

  public logout = () => this.delete<unknown>(`${process.env.REACT_APP_API_URL_USER}/signout`)

  public resendVerificationEmail = () =>
    this.get<unknown>(`${process.env.REACT_APP_API_URL_USER}/resend-verification-user`)

  public changePassword = (params: ChangePasswordDto) =>
    this.post<unknown>(`${process.env.REACT_APP_API_URL_USER}/change-pwd`, params)

  public forgotPassword = (params: ForgotPasswordDto) =>
    this.post<unknown>(`${process.env.REACT_APP_API_URL_USER}/forgot-pwd`, params)

  public renewPassword = (params: RenewPasswordDto) =>
    this.post<unknown>(`${process.env.REACT_APP_API_URL_USER}/renew-pwd`, params)

  public getUserInfo = (id: string) => this.get<ProfileResponse>(`${process.env.REACT_APP_API_URL_USER}/${id}`)

  public getProfile = () => this.get<ProfileResponse>(`${process.env.REACT_APP_API_URL_USER}/profile`)

  public updateProfile = (params: FormData) => {
    return this.put<{ accessToken: string }>(`${process.env.REACT_APP_API_URL_USER}/profile`, params)
  }

  public getUsers = (search: URLSearchParams) =>
    this.get<Pagination<any>>(`${process.env.REACT_APP_API_URL_USER}?${search.toString()}`)

  // -Model
  public getModelArchs = (search: URLSearchParams) => {
    return this.get<Pagination<any>>(`${process.env.REACT_APP_API_URL_MODEL}?${search.toString()}`)
  }

  public createModel = (params: CreateModelDto, idProject: string, idSite: string, idMachine: string) => {
    return this.post<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}/pipeline`,
      params
    )
  }

  public undeployModel = (idProject: string, idSite: string, idMachine: string, idModel: string) => {
    return this.delete<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}/pipeline/${idModel}`
    )
  }

  // - Project
  public getProjects = (search: URLSearchParams) => {
    return this.get<Pagination<ProjectModel>>(`${process.env.REACT_APP_API_URL_PROJECT}?${search.toString()}`)
  }

  public getProjectById = (id: string) => {
    return this.get<ProjectModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${id}`)
  }

  public createProjects = (params: CreateProjectDto) => {
    return this.post<ProjectModel>(`${process.env.REACT_APP_API_URL_PROJECT}`, params)
  }

  public updateProjects = (id: string, params: UpdateProjectDto) => {
    return this.put<ProjectModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${id}`, params)
  }

  public deleteProjects = (id: string) => {
    return this.delete<ProjectModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${id}`)
  }

  // - Member
  public getMembers = (search: URLSearchParams, idProject: string) => {
    return this.get<Pagination<any>>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/members?${search.toString()}`
    )
  }

  // - Member
  public getMemberById = (idProject: string, idMember: string) => {
    return this.get<Pagination<any>>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/members/${idMember}`)
  }

  public getMemberRole = (idProject: string) => {
    return this.get<MemberModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/members/my-role`)
  }

  public updateMember = (id: string, idMember: string, params: UpdateMemberDto) => {
    return this.put<ProjectModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${id}/members/${idMember}`, params)
  }

  public deleteMembers = (idProject: string, id: string) => {
    return this.delete<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/members/${id}`)
  }

  public createMembers = (params: CreateMemberDto, idProject: string) => {
    return this.post<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/members`, params)
  }

  public createUsers = (params: CreateMemberDto) => {
    return this.post<any>(`${process.env.REACT_APP_API_URL_PROJECT}`, params)
  }

  public getRoles = () => {
    return this.get<any>(`${process.env.REACT_APP_API_URL_USER}/roles`)
  }

  // - Site
  public getSites = (search: URLSearchParams, idProject: string) => {
    return this.get<Pagination<any>>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites?${search.toString()}`)
  }

  public createSites = (params: CreateSiteDto, idProject: string) => {
    return this.post<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites`, params)
  }

  public updateSites = (params: CreateSiteDto, idProject: string, idSite: string) => {
    return this.put<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}`, params)
  }

  public getSiteById = (idProject: string, idSite: string) => {
    return this.get<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}`)
  }

  public deleteSites = (idProject: string, id: string) => {
    return this.delete<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${id}`)
  }

  // - Machine
  public getMachines = (search: URLSearchParams, idProject: string, idSite: string) => {
    return this.get<Pagination<any>>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines?${search.toString()}`
    )
  }

  public getMachineAll = (search: URLSearchParams, idProject: string) => {
    return this.get<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/machines?${search.toString()}`)
  }

  public getMachineById = (idProject: string, idSite: string, idMachine: string) => {
    return this.get<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}`)
  }

  public getMachineOverviewById = (idProject: string, idSite: string, idMachine: string, search: URLSearchParams) => {
    return this.get<any>(
      `${
        process.env.REACT_APP_API_URL_PROJECT
      }/${idProject}/sites/${idSite}/machines/${idMachine}/overview?${search.toString()}`
    )
  }

  public setMachineThreshold = (
    idProject: string,
    idSite: string,
    idMachine: string,
    threshold: SetMachineThresholdDto
  ) => {
    return this.put<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}/threshold`,
      threshold
    )
  }

  public createMachines = (params: CreateMachineDto, idProject: string, idSite: string) => {
    return this.post<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines`, params)
  }

  public updateMachines = (params: Partial<CreateMachineDto>, idProject: string, idSite: string, idMachine: string) => {
    return this.put<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}`,
      params
    )
  }

  public deleteMachines = (idProject: string, idSite: string, id: string) => {
    return this.delete<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${id}`)
  }

  public resolveMachines = (params: Partial<ResolveMachineDto>, idProject: string, idMachine: string) => {
    return this.put<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/machines/${idMachine}/resolve`, params)
  }

  public getMachineStatus = (idProject: string, idSite: string) => {
    return this.get<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/summary`)
  }

  // - Sensor
  public getSensors = (search: URLSearchParams, idProject: string, idSite: string, idMachine: string) => {
    return this.get<Pagination<any>>(
      `${
        process.env.REACT_APP_API_URL_PROJECT
      }/${idProject}/sites/${idSite}/machines/${idMachine}/sensors?${search.toString()}`
    )
  }

  public createSensors = (params: CreateMachineDto, idProject: string, idSite: string) => {
    return this.post<any>(`${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines`, params)
  }

  public deleteSensors = (idProject: string, idSite: string, idMachine: string, id: string) => {
    return this.delete<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${idProject}/sites/${idSite}/machines/${idMachine}/sensors/${id}`
    )
  }

  // - Measurement
  public getMeasurements = (projectId: string, search: URLSearchParams) => {
    return this.get<Pagination<MeasurementModel>>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements?${search.toString()}`
    )
  }

  public getMeasurementById = (projectId: string, id: string) => {
    return this.get<MeasurementModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements/${id}`)
  }

  public createMeasurements = (projectId: string, params: CreateMeasurementDto) => {
    return this.post<MeasurementModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements`, params)
  }

  public updateMeasurements = (projectId: string, id: string, params: UpdateMeasurementDto) => {
    return this.put<MeasurementModel>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements/${id}`,
      params
    )
  }

  public deleteMeasurements = (projectId: string, id: string) => {
    return this.delete<MeasurementModel>(`${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements/${id}`)
  }

  public uploadMeasurementData = (projectId: string, params: UploadMeasurementDto) => {
    const formData = objectToFormData({ file: params.file, limit: params.limit })
    return this.post<MeasurementModel>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements/${params.id}/values`,
      formData
    )
  }

  public fetchMeasurementData = (projectId: string, id: string, search: URLSearchParams) => {
    return this.get<string[]>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/measurements/${id}/values?${search.toString()}`
    )
  }

  // - Annotation
  public getAnnotation = (projectId: string, machineId: string, search: URLSearchParams) => {
    return this.get<string[]>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/machines/${machineId}/annotations?${search.toString()}`
    )
  }

  public postAnnotation = (
    projectId: string,
    machineId: string,
    params: { startTime: string; stopTime: string; type: string }
  ) => {
    return this.post(`${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/machines/${machineId}/annotations`, params)
  }

  public deleteAnnotation = (projectId: string, machineId: string, annotationId: string) => {
    return this.delete<any>(
      `${process.env.REACT_APP_API_URL_PROJECT}/${projectId}/machines/${machineId}/annotations/${annotationId}`
    )
  }
}

const api = Api.getInstance()

export default api
