import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { storage } from '@defines'

import { State } from './type'

const initialState: State = {
  skin: 'default',
  mode: 'light',
  appBar: 'fixed',
  footer: 'fixed',
  navHidden: false,
  appBarBlur: true,
  direction: 'ltr',
  navCollapsed: false,
  themeColor: 'primary',
  contentWidth: 'full',
  layout: 'vertical',
  lastLayout: 'vertical',
  verticalNavToggleType: 'accordion',
  toastPosition: 'top-center'
}

export const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    reset: () => initialState,
    setSetting: (state, action: PayloadAction<State>) => ({
      ...action.payload
    }),
    saveSettings: (state, action: PayloadAction<State>) => {
      localStorage.setItem(storage.SETTING, JSON.stringify(action.payload))
      return {
        ...action.payload
      }
    },
    updateSetting: (state, action: PayloadAction<Partial<State>>) => {
      const newState: State = {
        ...state,
        ...action.payload
      }
      localStorage.setItem(storage.SETTING, JSON.stringify(newState))
      return newState
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
