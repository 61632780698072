import './lang'
import './index.css'
import './mock'
import 'prismjs/themes/prism-tomorrow.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
// import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { ThemeComponent } from '@theme'

import App from './App'
import { store } from './store'
// import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ThemeComponent>
      <App />
    </ThemeComponent>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
