import { InputBaseProps } from '@mui/material'
import { MuiChipsInput, MuiChipsInputProps } from 'mui-chips-input'
import { useRef, useState } from 'react'

type Props = MuiChipsInputProps

export const InputChips: React.FC<Props> = props => {
  const [inputValue, setInputValue] = useState('')
  const refInputValue = useRef<string>()
  const refChips = useRef<string[]>(props.value || [])
  const refChipsInput = useRef<HTMLInputElement>()

  const updateInputValue = (value: string) => {
    refInputValue.current = value
    setInputValue(value)
  }

  const checkCreateChipFromValue = () => {
    const value = refInputValue.current
    if (typeof value !== 'string') return

    const valueTrimed = value.trim()
    if (!valueTrimed) return

    refChips.current.push(value)

    if (props.onChange) props.onChange(refChips.current)
  }

  const onInputChange = (v: string) => {
    if (/(.+)[\;|\,]$/.test(v)) {
      checkCreateChipFromValue()
      updateInputValue('')
    } else {
      updateInputValue(v)
    }
    if (props.onInputChange) props.onInputChange(v)
  }

  const onBlur: InputBaseProps['onBlur'] = (...e) => {
    checkCreateChipFromValue()
    updateInputValue('')
    if (props.onBlur) props.onBlur(...e)
  }

  const onChange = (value: string[]) => {
    const valueTrimed = value.map(o => o.trim()).filter(o => o)
    refChips.current = valueTrimed
    if (props.onChange) props.onChange(valueTrimed)
  }

  return (
    <MuiChipsInput
      {...props}
      clearInputOnBlur
      ref={refChipsInput as any}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
