import { ReactNode, Suspense, useEffect, useState } from 'react'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'

import Layout from '@core/layouts/Layout'
import { useSettings } from '@core/hooks/useSettings'
import { Loading } from '@components'
import { appSelector, workspaceSelector } from '@store'
import { NavLink, VerticalNavItemsType } from '@core/types'

import { VerticalNavItems } from './navigationProject'
import VerticalAppBarContent from './components/vertical/AppBarContent'

interface Props {
  children: ReactNode
  contentHeightFixed?: boolean
}

const ProjectLayout = ({ children, contentHeightFixed }: Props) => {
  const { settings, saveSettings } = useSettings()
  const isDevelopMode = useSelector(appSelector.isDevelopMode)
  const [items, setItems] = useState<VerticalNavItemsType>()
  const project = useSelector(workspaceSelector.selectProject)

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }

  useEffect(() => {
    const configItems = [...(VerticalNavItems(isDevelopMode, project) as NavLink[])]
    setItems(configItems)
  }, [isDevelopMode, project])

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: items
        },
        appBar: {
          content: props => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
    >
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </Layout>
  )
}

export default ProjectLayout
