import styled from '@emotion/styled'

import { Logo } from './Logo'

const Wrap = styled.div`
  position: fixed;
  top: 0;
  height: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GlobalLoading: React.FC = () => (
  <Wrap>
    <Logo height={44} />
  </Wrap>
)
