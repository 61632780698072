import { UIEventHandler, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Pagination, PaginationQueries } from '@types'
import { DEFAULT_QUERIES } from '@defines'
import { queriesToString } from '@utils'
import { appActions } from '@store'

type Params<RecordType> = {
  fetch: (search: URLSearchParams) => Promise<Pagination<RecordType> | undefined>
  transformItemsToOptions: (i: RecordType) => string
}

export const useAutoComplete = <RecordType extends object>(params: Params<RecordType>) => {
  const dispatch = useDispatch<AppDispatch>()
  const [loading, setLoading] = useState(true)
  const refQueries = useRef<PaginationQueries>(Object.assign({}, DEFAULT_QUERIES))
  const [data, setData] = useState<Pagination<RecordType>>({
    items: null,
    pagination: { totalPages: 1, currentPage: 1, totalRecords: 1 }
  })
  const [options, setOptions] = useState<string[]>([])
  const refTimeoutSearch = useRef<NodeJS.Timeout>()

  const clearRefTimeoutSearch = () => {
    if (refTimeoutSearch.current) {
      clearTimeout(refTimeoutSearch.current)
      refTimeoutSearch.current = undefined
    }
  }

  const fetch = async () => {
    try {
      setLoading(true)
      const response = await params.fetch(queriesToString({ currentQueries: refQueries.current }))
      return response
    } catch (error) {
      dispatch(
        appActions.setNotice({
          message: error instanceof Error ? error.message : typeof error === 'string' ? error : 'error.unknow',
          severity: 'error'
        })
      )
    } finally {
      setLoading(false)
    }
  }

  const onOpen = async () => {
    refQueries.current = Object.assign({}, DEFAULT_QUERIES)
    const response = await fetch()
    if (response) setData(response)
  }

  const onChangeInput = (v: string) => {
    refQueries.current = Object.assign({}, DEFAULT_QUERIES, { keyword: v || undefined })

    clearRefTimeoutSearch()
    refTimeoutSearch.current = setTimeout(() => {
      fetch().then(r => r && setData(r))
      clearRefTimeoutSearch()
    }, 500)
  }

  const onScroll: UIEventHandler<HTMLElement> = async e => {
    const element = e.target as HTMLElement
    if (
      !loading &&
      element.scrollTop + element.offsetHeight + 1 >= element.scrollHeight &&
      data.pagination.currentPage < data.pagination.totalPages
    ) {
      element.scrollTo(0, element.scrollHeight)
      refQueries.current.page++
      const response = await fetch()
      if (response)
        setData(state => ({
          items: [...(state.items || []), ...(response.items || [])],
          pagination: response.pagination
        }))
    }
  }

  useEffect(() => {
    const items: RecordType[] = data.items || []
    setOptions(items.map(params.transformItemsToOptions))
  }, [data])

  return {
    data,
    options,
    loading,
    fetch,
    onOpen,
    onScroll,
    onChangeInput
  }
}
