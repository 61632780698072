import chatCoreReducer from '@core/store/chat'
import userCoreReducer from '@core/store/user'
import emailCoreReducer from '@core/store/email'
import invoiceCoreReducer from '@core/store/invoice'
import calendarCoreReducer from '@core/store/calendar'
import permissionsCoreReducer from '@core/store/permissions'

import { reducer as configsReducer } from './configs'
import { reducer as settingsReducer } from './settings'
import { reducer as workspaceReducer } from './workspace'
import { reducer as userReducer } from './users'
import { reducer as appReducer } from './app'

export const reducer = {
  app: appReducer,
  user: userReducer,
  setting: settingsReducer,
  workspace: workspaceReducer,
  configs: configsReducer,

  chatCore: chatCoreReducer,
  userCore: userCoreReducer,
  emailCore: emailCoreReducer,
  invoiceCore: invoiceCoreReducer,
  calendarCore: calendarCoreReducer,
  permissionsCore: permissionsCoreReducer
}
