import { fromFlux, Table } from '@influxdata/giraffe'
import { useEffect, useState } from 'react'

import { chartArrayDataToCSVForm } from '@utils'
import { GAUGE_COLOR, STATUS } from '@defines'
import { Thresholds } from '@types'

type Params = {
  fetch?: () => void
  chartDataArr?: any
  thresholds?: Thresholds
}

export const useGaugeChart = (params: Params): { score: string; chartData: any; configGauge: any } => {
  const [score, setScore] = useState<string>('0.00')
  const [chartData, setChartData] = useState<{ table: Table; fill: string[] }>()
  const { chartDataArr, thresholds } = params

  useEffect(() => {
    if (chartDataArr?.length) {
      const parseValue = chartArrayDataToCSVForm(chartDataArr)
      const table = fromFlux(parseValue.data).table
      const fill = table.columnKeys.filter(k => table.getColumnType(k) === 'string')
      setChartData({ table, fill })
    } else {
      const table = fromFlux('parseValue.data').table
      setChartData({ table, fill: [] })
    }
  }, [chartDataArr])

  useEffect(() => {
    if (chartDataArr?.length) {
      const scoreResult = Number(
        chartDataArr[0]
          .split(',')
          .find((item: any) => {
            return item.includes('_value')
          })
          ?.split(':')[1]
      ).toFixed(2)
      setScore(scoreResult)
    }
  }, [chartDataArr])

  const getThresholdValue = (status: STATUS) => {
    const thresholdValues = thresholds || {
      2: STATUS.HEALTHY,
      4: STATUS.WARNING,
      5: STATUS.ALARM
    }

    const keys = Object.keys(thresholdValues)
    const values = Object.values(thresholdValues)
    return keys[values.indexOf(status)]
  }

  const configGauge = {
    table: chartData?.table,
    layers: [
      {
        type: 'gauge',
        decimalPlaces: { isEnforced: false, digits: 2 },
        gaugeColors: [
          { id: 'g', type: 'min', hex: GAUGE_COLOR.healthy, name: 'color_0', value: 0 },
          {
            id: 'c',
            type: 'background',
            hex: GAUGE_COLOR.warning,
            name: 'color_2',
            value: getThresholdValue(STATUS.HEALTHY)
          },
          {
            id: 'e',
            type: 'background',
            hex: GAUGE_COLOR.alarm,
            name: 'color_4',
            value: getThresholdValue(STATUS.WARNING)
          },
          { id: 'h', type: 'max', hex: '', name: 'color_5', value: getThresholdValue(STATUS.ALARM) }
        ],
        gaugeSize: 4.76,
        gaugeTheme: {
          minLineWidth: 20,
          valuePositionXOffset: 20,
          valuePositionYOffset: 20
        }
      }
    ]
  }

  return {
    score,
    chartData,
    configGauge
  }
}
