import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { userSelector } from '@store'
import { routers } from '@defines'

import { useRouteRender, Options as OptionsRouteRender } from './useRouteRender'

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const userData = useSelector(userSelector.selectData)

  // const location = useLocation()
  // console.log('localtion', location)

  useEffect(() => {
    if (!userData) {
      const search = new URLSearchParams()
      if (location.pathname !== '/' && location.pathname !== routers.LOGIN)
        search.append('redirect', location.href.replace(location.origin, ''))
      const queries = search.toString()

      navigate(`${routers.LOGIN}${queries ? `?${queries}` : ''}`)
    }
  }, [userData])

  return <>{children}</>
}

export const useGuard = (node: ReactNode, options?: OptionsRouteRender) => {
  return <Container>{useRouteRender(node, options)}</Container>
}
