import { useEffect, useRef, useState } from 'react'

export function useComponentVisible(initialIsVisible: boolean) {
  const [showDialogAction, setShowDialogAction] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !(ref.current as any).contains(event.target)) {
      setShowDialogAction(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, showDialogAction, setShowDialogAction }
}
