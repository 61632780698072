import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Settings, SettingsContextValue } from '@core/types'
import { settingsActions, settingsSelector } from '@store'

export const useSettings = (): SettingsContextValue => {
  const settings = useSelector(settingsSelector.selectSettings)
  const dispatch = useDispatch()

  const saveSettings = (settingData: Settings) => {
    dispatch(settingsActions.saveSettings(settingData))
  }

  return {
    settings,
    saveSettings
  }
}
