import { useState } from 'react'
import { useDispatch } from 'react-redux'

import api from '@api'
import { appActions, userActions } from '@store'
import { channelToken } from '@broadcast'

export const useLogout = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const cleanStore = () => {
    dispatch(userActions.clean())
    dispatch(appActions.clean())
  }

  const logout = async (options?: { isFetch?: boolean; pushChannel?: boolean }) => {
    try {
      setLoading(true)

      if (api.isAuthorization() && options?.isFetch !== false) {
        try {
          await api.logout()
        } catch (error) {
          // eslint-disable-next-line
          console.log('Logout error', error)
        }
      }
      api.removeToken()

      cleanStore()

      if (options?.pushChannel !== false) channelToken.deleteData()
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return { logout, loading }
}
