import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { DURATION } from '@defines'
import { appActions } from '@store'

import { useDebounce } from './useDebounce'

type Queries = { duration?: DURATION; range?: [number, number]; typeFilter: 'duration' | 'range' }

type Params = {
  fetch: (search: URLSearchParams) => Promise<string[] | undefined>
  fetchInterval?: {
    state: boolean
    timeInterval: number
  }
}

const DEFAULT_QUERIES: Queries = {
  duration: DURATION['1H'],
  range: undefined,
  typeFilter: 'duration'
}

export const useInfluxChart = (params: Params) => {
  const dispatch = useDispatch<AppDispatch>()
  const [data, setData] = useState<string[]>()
  const [loading, setLoading] = useState(true)
  const [queries, setQueries] = useState<Queries>(DEFAULT_QUERIES)
  const refQueries = useRef<Queries>(queries)
  const { debounce } = useDebounce()
  const location = useLocation()

  const updateQueries = (q: Partial<Queries>) => {
    const nq: Partial<Queries> = {}
    if (
      (Object.keys(q).includes('duration') &&
        typeof q.duration === 'string' &&
        Object.values(DURATION).includes(q.duration)) ||
      (Object.keys(q).includes('range') && q.range?.length && !q.duration)
    ) {
      nq.duration = q.duration
    }
    if (Object.keys(q).includes('typeFilter') && q.typeFilter) nq.typeFilter = q.typeFilter
    if (
      (Object.keys(q).includes('range') && q.range) ||
      (Object.keys(q).includes('duration') && q.duration && !q.range)
    )
      nq.range = q.range

    setQueries(state => {
      const mq = {
        ...state,
        ...nq
      }
      refQueries.current = mq
      return mq
    })
  }

  const queriesDuration = (duration: DURATION | [number, number]) => {
    if (typeof duration === 'object' && duration.length === 2) {
      updateQueries({ range: duration as [number, number], typeFilter: 'range', duration: undefined })
    } else if (Object.values(DURATION).includes(duration as any)) {
      updateQueries({ duration: duration as DURATION, typeFilter: 'duration', range: undefined })
    }
  }

  const queriesToString = (options?: { defaultQueries?: boolean }) => {
    const search = new URLSearchParams()
    Object.keys(options?.defaultQueries ? DEFAULT_QUERIES : refQueries.current).forEach(o => {
      const value = (refQueries.current as any)[o]
      if (value) search.append(o, value)
    })
    return search
  }

  const fetchHandle = async () => {
    try {
      const search = new URLSearchParams()
      if (queries.duration) search.append('duration', `-${queries.duration}`)
      if (queries.range) search.append('range', `${JSON.stringify(queries.range.map(o => o / 1000))}`)
      search.append('typeFilter', queries.typeFilter)
      const response = await params.fetch(search)
      setData(response)
    } catch (error) {
      dispatch(
        appActions.setNotice({
          message: error instanceof Error ? error.message : typeof error === 'string' ? error : 'error.unknow',
          severity: 'error'
        })
      )
    }
  }

  const fetch = async () => {
    await debounce(fetchHandle)
    const search = new URLSearchParams(window.location.search)
    const duration = search.get('duration')
    if (duration === queries.duration) setLoading(false)
  }

  useEffect(() => {
    if (!location.search) return
    const qparse = JSON.parse(
      '{"' + decodeURI(location.search.replace('?', '').replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
    )
    if (qparse.range) qparse.range = qparse.range.split('%2C').map((o: string) => parseInt(o, 10))
    updateQueries(qparse)
  }, [])

  useEffect(() => {
    const url = `${window.location.pathname}?${queriesToString()}`
    window.history.replaceState({}, '', url)
  }, [queries])

  useEffect(() => {
    setLoading(true)
    if (params.fetchInterval && params.fetchInterval.state) {
      const fetchInterval = setInterval(() => {
        fetch()
      }, params.fetchInterval.timeInterval)
      return () => clearInterval(fetchInterval)
    } else {
      fetch()
    }
  }, [queries])

  return {
    fetch,
    queriesDuration,
    queries,
    data,
    loading
  }
}
