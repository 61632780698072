export const capitalize = (text: string) => {
  let arr = text.split(' ')
  arr = arr.map(o => o[0].toUpperCase() + o.slice(1))
  return arr.join(' ')
}

export const transferDonerToTitleCase = (text: string) => {
  let arr = text.split('-')
  arr = arr.map(o => o[0].toUpperCase() + o.slice(1))
  return arr.join('')
}

export const renderUserName = (firstName: string, lastName: string) => `${firstName} ${lastName}`
