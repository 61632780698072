import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'

import { userSelector, workspaceActions, workspaceSelector } from '@store'
import { GlobalLoading } from '@components'
import { useController } from '@hooks'
import api from '@api'
import { RESPONSE_CODE, routers } from '@defines'

export const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { controller } = useController()
  const { idProject, idSite, idMachine } = useParams()
  const project = useSelector(workspaceSelector.selectProject)
  const site = useSelector(workspaceSelector.selectSite)
  const machine = useSelector(workspaceSelector.selectMachine)
  const role = useSelector(workspaceSelector.selectRole)
  const user = useSelector(userSelector.selectData)
  const [isFetchProject, setIsFetchProject] = useState(false)
  const [isFetchSite, setIsFetchSite] = useState(false)
  const [isFetchMachine, setIsFetchMachine] = useState(false)
  const [loading, setLoading] = useState(true)

  const onError = (error: unknown) => {
    if (error instanceof AxiosError) {
      const { response } = error
      // eslint-disable-next-line
      const status: number | undefined = response?.status
      // eslint-disable-next-line
      const resCode: RESPONSE_CODE = response?.data?.message

      if (status === 404) {
        return navigate(routers.PROJECT_LIST, { replace: true })
      }
      if (resCode === RESPONSE_CODE.PERMISSION_DENIED) {
        return navigate(routers.PROJECT_LIST, { replace: true })
      }
    }
  }

  const fetchRole = async (projectId: string) =>
    controller(
      async () => {
        const response = await api.getMemberRole(projectId)
        const { data } = response
        dispatch(workspaceActions.setRole(data.name))
      },
      {
        onLoading: setIsFetchProject,
        onError
      }
    )

  const fetchProject = async (projectId: string) =>
    controller(
      async () => {
        const response = await api.getProjectById(projectId)
        const { data } = response
        dispatch(workspaceActions.setProject(data))
      },
      {
        onLoading: setIsFetchProject,
        onError
      }
    )

  const fetchSite = async (projectId: string, siteId: string) =>
    controller(
      async () => {
        const response = await api.getSiteById(projectId, siteId)
        const { data } = response
        dispatch(workspaceActions.setSite(data))
      },
      {
        onLoading: setIsFetchSite,
        onError
      }
    )

  const fetchMachine = async (projectId: string, siteId: string, machineId: string) =>
    controller(
      async () => {
        const response = await api.getMachineById(projectId, siteId, machineId)
        const { data } = response
        dispatch(workspaceActions.setMachine(data))
      },
      {
        onLoading: setIsFetchMachine,
        onError
      }
    )

  useEffect(() => {
    if (!idProject) {
      setIsFetchProject(false)
      dispatch(workspaceActions.setProject(undefined))
    } else if (idProject !== project?.id && !isFetchProject) {
      fetchProject(idProject)
    }
  }, [idProject, isFetchProject])

  useEffect(() => {
    if (!idSite || !idProject) {
      setIsFetchSite(false)
      dispatch(workspaceActions.setSite(undefined))
    } else if (idSite !== site?.id && !isFetchSite) {
      fetchSite(idProject, idSite)
    }
  }, [idProject, idSite, isFetchSite])

  useEffect(() => {
    if (!idSite || !idProject || !idMachine) {
      setIsFetchMachine(false)
      dispatch(workspaceActions.setMachine(undefined))
    } else if (idMachine !== machine?.id && !isFetchMachine) {
      fetchMachine(idProject, idSite, idMachine)
    }
  }, [idProject, idSite, idMachine, isFetchMachine])

  useEffect(() => {
    if (!isFetchProject && !isFetchSite && !isFetchMachine) setLoading(false)
  }, [isFetchProject, isFetchSite, isFetchMachine])

  useEffect(() => {
    if (idProject && user) {
      fetchRole(idProject)
    }
  }, [role, user, idProject])

  if (loading) return <GlobalLoading />

  return <>{children}</>
}

export const useWorkspacePageRender = (node: ReactNode) => {
  return <Container>{node}</Container>
}
