import { Suspense } from 'react'

import { Router } from '@router'
import { useApp, useNotice } from '@hooks'
import { GlobalLoading } from '@components'

function App() {
  const { inited } = useApp()
  const { render: renderNotice } = useNotice()

  if (!inited) return <GlobalLoading />

  return (
    <Suspense fallback={<GlobalLoading />}>
      <Router />
      {renderNotice()}
    </Suspense>
  )
}

export default App
