import mock from '@core/@fake-db/mock'

export type IconsDataType = {
  icon: string
}

const icons: IconsDataType[] = [
  { icon: 'airplane' },
  { icon: 'ab-testing' },
  { icon: 'widgets-outline' },
  { icon: 'whatsapp' },
  { icon: 'water-well-outline' }
]

mock.onGet('/api/icons/data').reply(() => [200, icons])
