import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ENVIRONMENT, storage } from '@defines'
import { appActions, settingsActions } from '@store'
import { channelToken } from '@broadcast'
import api from '@api'

import { useDecodeUser, useLogout } from '../user'

export const useApp = () => {
  const dispatch = useDispatch()
  const { decode } = useDecodeUser()
  const { logout } = useLogout()
  const [inited, setInited] = useState(false)

  const initEnvironment = () => {
    const environment = localStorage.getItem(storage.ENVIRONMENT)
    dispatch(appActions.setDevelopMode(environment === ENVIRONMENT.DEVELOPMENT))
  }

  const initStorage = () => {
    try {
      const settingRaw = localStorage.getItem(storage.SETTING)
      const setting = settingRaw ? JSON.parse(settingRaw) : undefined
      if (setting) dispatch(settingsActions.setSetting(setting))
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const initBroadCast = () => {
    channelToken.addEventListenner('update', data => {
      if (data) {
        const { token, save } = data
        api.setToken(token, { saveToken: save, pushChannel: false })
        decode()
      }
    })
    channelToken.addEventListenner('delete', () => {
      logout({ pushChannel: false })
    })
  }

  const initialize = () => {
    try {
      initEnvironment()
      initStorage()
      initBroadCast()
      decode()
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    } finally {
      setInited(true)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  return { inited }
}
