import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { appActions } from '@store'
import { RESPONSE_CODE, RESPONSE_MESSAGE } from '@defines'

import { useLogout } from '../user'

type Notice = (params: Parameters<typeof appActions.setNotice>[0]) => void

type Handle<T = unknown> = (notice: Notice) => Promise<T>

type Options = {
  onLoading?: (value: boolean) => void
  onSuccess?: (notice: Notice) => void
  onError?: (error: unknown, notice: Notice) => void
  onDone?: () => void
  notice?: {
    show?: boolean
    ignoreCodes?: number[]
    fallbackMessage: string
  }
}

export const useController = () => {
  const { logout } = useLogout()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const notice: Notice = params => {
    dispatch(appActions.setNotice(params))
  }

  const controller = async <T = unknown>(handle: Handle<T>, options?: Options): Promise<T | undefined> => {
    const { notice: noticeOptions, onDone, onSuccess, onError, onLoading } = options || {}
    const isShowNotice = noticeOptions?.show
    const fallbackMessage = noticeOptions?.fallbackMessage
    const noticeIgnoreCode = noticeOptions?.ignoreCodes || []
    let result: T | undefined

    try {
      if (onLoading) onLoading(true)
      result = await handle(notice)
      if (onSuccess) onSuccess(notice)
    } catch (error) {
      let isNoticed = false
      const noticeError = (params: Parameters<typeof notice>[0]) => {
        if (isNoticed) return
        isNoticed = true
        notice(params)
      }

      if (onError) onError(error, noticeError)

      if (error instanceof AxiosError) {
        const { response } = error
        // eslint-disable-next-line
        const status: number | undefined = response?.status
        // eslint-disable-next-line
        const resCode: RESPONSE_CODE = response?.data?.message

        if (status === 401 || resCode === RESPONSE_CODE.TOKEN_EXPIRIED) {
          logout()
        }
      }

      if (isShowNotice !== false) {
        let message = ''
        if (error instanceof AxiosError) {
          const errorCode = error.response?.data?.message
          const status: number | undefined = error.response?.status
          const messageResponse = (RESPONSE_MESSAGE as any)[errorCode]

          if (!errorCode || !noticeIgnoreCode.includes(errorCode)) {
            if (errorCode && messageResponse) message = t(messageResponse)
            else if (status === 401) message = t('error.unauthorized')
            else message = error.message
          }
        } else if (fallbackMessage) {
          message = fallbackMessage
        } else if (error instanceof Error) {
          message = error.message
        } else if (typeof error === 'string') {
          message = error
        } else message = t('unknow_error')

        if (message) noticeError({ message, severity: 'error' })
      }
    } finally {
      if (onLoading) onLoading(false)
      if (onDone) onDone()
    }

    return result
  }

  return { controller }
}
