import { VerticalNavItemsType } from '@core/types'
import { navigationCoreVertical } from '@core/layouts/navigation'
import { routers } from '@defines'
import { ProjectModel } from '@types'

export const VerticalNavItems = (isDevMode: boolean, project?: ProjectModel): VerticalNavItemsType => [
  ...(project
    ? [
        {
          title: project.name
        },
        {
          title: 'Overview',
          icon: 'material-symbols:home-outline-rounded',
          path: routers.PROJECT_OVERVIEW.replace(':idProject', project.id as string)
        },
        {
          title: 'Configuration',
          icon: 'fluent:extended-dock-24-regular',
          path: routers.PROJECT_CONFIG_LIST.replace(':idProject', project.id as string)
        },
        {
          title: 'Machine',
          icon: 'fluent:glance-24-regular',
          children: [
            {
              title: 'Overview',
              path: routers.PROJECT_CONFIG_OVERVIEW.replace(':idProject', project.id as string)
            },
            {
              title: 'Anomaly Detection',
              path: routers.PROJECT_MACHINE_OVERVIEW.replace(':idProject', project.id as string)
            }
          ]
        }
      ]
    : [
        {
          title: 'Projects',
          icon: 'material-symbols:home-outline-rounded',
          path: routers.PROJECT_LIST
        }
      ]),
  ...(isDevMode ? navigationCoreVertical : [])
]
