import { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { routers } from '@defines'

const LoginPage = lazy(() => import('@pages/login'))
const RegisterPage = lazy(() => import('@pages/register'))
const ForgotPasswordPage = lazy(() => import('@pages/forgotPassword'))
const VerifyPage = lazy(() => import('@pages/verify'))
const ResetPasswordPage = lazy(() => import('@pages/resetPassword'))
const UnauthorizedPage = lazy(() => import('@pages/unauthorized'))
const NotFoundPage = lazy(() => import('@pages/notfound'))
const ServerErrorPage = lazy(() => import('@pages/serverError'))
const UnderMaintenancePage = lazy(() => import('@pages/underMaintenance'))
const ComingSoonPage = lazy(() => import('@pages/comingSoon'))
const PrivacyPolicyPage = lazy(() => import('@pages/privacyPolicy'))

import { useGuard } from './hooks'
import { useRouteRender } from './hooks/useRouteRender'
import { EmptyRouter } from './EmptyRouter'
import { UserRouter } from './UserRouter'
import { ProjectRouter } from './ProjectRouter'

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routers.LOGIN} element={useRouteRender(<LoginPage />)} />
        <Route path={routers.REGISTER} element={useRouteRender(<RegisterPage />)} />
        <Route
          path={routers.VERIFY}
          element={useRouteRender(<VerifyPage />, { checkVerify: false, autoRedirect: false })}
        />
        <Route path={routers.FORGOT_PASSWORD} element={useRouteRender(<ForgotPasswordPage />)} />
        <Route path={routers.RESET_PASSWORD} element={useRouteRender(<ResetPasswordPage />)} />
        <Route path={routers.UNAUTHORIZED} element={useRouteRender(<UnauthorizedPage />)} />
        <Route path={routers.NOT_FOUND} element={useRouteRender(<NotFoundPage />)} />
        <Route path={routers.SERVER_ERROR} element={useRouteRender(<ServerErrorPage />)} />
        <Route path={routers.COMMING_SOON} element={useRouteRender(<ComingSoonPage />)} />
        <Route path={routers.UNDER_MAINTENANCE} element={useRouteRender(<UnderMaintenancePage />)} />
        <Route path={routers.PRIVACY_POLICY} element={useRouteRender(<PrivacyPolicyPage />)} />
        <Route path='/project/*' element={useGuard(<ProjectRouter />)} />
        <Route path='/user/*' element={useGuard(<UserRouter />)} />
        <Route path='*' element={useGuard(<EmptyRouter />)} />
      </Routes>
    </BrowserRouter>
  )
}
