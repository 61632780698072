import mock from '@core/@fake-db/mock'

import '@core/@fake-db/cards'
import '@core/@fake-db/table'
import '@core/@fake-db/auth/jwt'
import '@core/@fake-db/apps/chat'
import '@core/@fake-db/pages/faq'
import '@core/@fake-db/apps/email'
import '@core/@fake-db/apps/invoice'
import '@core/@fake-db/autocomplete'
import '@core/@fake-db/apps/userList'
import '@core/@fake-db/apps/calendar'
import '@core/@fake-db/pages/pricing'
import '@core/@fake-db/pages/profile'
import '@core/@fake-db/iconify-icons'
import '@core/@fake-db/app-bar-search'
import '@core/@fake-db/apps/permissions'
import '@core/@fake-db/pages/help-center'
import '@core/@fake-db/server-side-menu/vertical'
import '@core/@fake-db/server-side-menu/horizontal'

// import './user'

mock.onAny().passThrough()
