import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import UserLayout from '@layouts/UserLayout'
import { routers, user } from '@defines'

const UserProfilePage = lazy(() => import('@pages/user/profile'))
const AccountSettingsPage = lazy(() => import('@pages/user/accountSettings'))

export const UserRouter: React.FC = () => {
  return (
    <UserLayout>
      <Routes>
        <Route index element={<UserProfilePage />} />
        <Route path={user.PROFILE} element={<UserProfilePage />} />
        <Route path={`${user.PROFILE}/:tab`} element={<UserProfilePage />} />
        <Route path={user.ACCOUNT_SETTING} element={<AccountSettingsPage />} />
        <Route path={`${user.ACCOUNT_SETTING}/:tab`} element={<AccountSettingsPage />} />
        <Route path='*' element={<Navigate to={routers.USER} replace />} />
      </Routes>
    </UserLayout>
  )
}
