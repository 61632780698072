import { PaletteMode } from '@mui/material'

import { Skin } from '@core/types'
import palette from '@core/theme/palette'

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  const pal = palette(mode, skin, { lightColor: '0, 0, 0' })
  const custom = {
    primary: {
      light: '#4CAF50',
      main: '#4CAF50',
      dark: '#4CAF50',
      contrastText: pal.primary.contrastText
    },
    success: {
      light: '#4CAF50',
      main: '#4CAF50',
      dark: '#4CAF50',
      contrastText: pal.primary.contrastText
    }
  }

  return {
    ...pal,
    ...custom
  }
}

export default DefaultPalette
