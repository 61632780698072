import { Theme } from '@mui/material/styles'

import { Skin } from '@core/types'

const Popover = (theme: Theme, skin: Skin) => ({
  MuiPopover: {
    styleOverrides: {
      root: {
        '& .MuiPopover-paper': {
          boxShadow: theme.shadows[skin === 'bordered' ? 0 : 6],
          ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}` })
        }
      }
    }
  }
})

export default Popover
