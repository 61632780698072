import { useEffect, ReactNode } from 'react'
import { Direction as DirectionType } from '@mui/material'

interface DirectionProps {
  children: ReactNode
  direction: DirectionType
}

const Direction = (props: DirectionProps) => {
  const { children, direction } = props

  useEffect(() => {
    document.dir = direction
  }, [direction])

  return <>{children}</>
}

export default Direction
