import React, { useState, useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Theme, useTheme } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const WrapPicker = styled.div`
  display: flex;
  margin-top: 10px;

  & > *:not(:last-child) {
    margin-right: 2px;
  }
`

const pickerStyle = css`
  border-radius: 4px;
`

const DateTimeShow = styled(DateTimePicker)<{ theme: Theme }>`
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => `rgba(${theme.palette.customColors.main}, 0.22)`} !important;
  }
`

const DatePicker = styled(StaticDateTimePicker)<{ theme: Theme }>`
  ${pickerStyle}
  background-color: ${({ theme }) => theme.palette.action.hover};
`

const TimePicker = styled(FormControl)<{ theme: Theme }>`
  ${pickerStyle}
  background-color: ${({ theme }) => theme.palette.action.hover};
  overflow: hidden;

  label {
    position: unset;
    transform: unset;
    margin: 16px 0 8px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .MuiInputBase-root.MuiInput-root {
    margin-top: 16px;

    &,
    & select {
      height: 100%;
    }
    select {
      padding: 0;
      background-color: unset !important;
      option {
        text-align: center;
        padding: 5px 20px 5px 10px;
        font-size: 12px;

        &:checked {
          background-color: ${({ theme }) => theme.palette.primary.main} !important;
        }
      }
    }
    select[multiple]:focus option:checked {
      background: ${({ theme }) =>
        `${theme.palette.primary.main} linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%);`};
    }
  }

  .Mui-focused:after,
  .MuiInputBase-root::before {
    border: none !important;
  }
`

type Props = {
  onChange?: (timestamp: number) => void
  value?: number
}

export const DateTimeInputPicker: React.FC<Props> = props => {
  const theme = useTheme()
  const [value, setValue] = useState<Dayjs>(dayjs().set('minute', 0).set('second', 0).set('millisecond', 0))

  const handleChangeMultiple = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        const nov = parseInt(options[i].value, 10)
        const nv = value.set('hour', nov).set('minute', 0).set('second', 0).set('millisecond', 0)
        setValue(nv)
        return
      }
    }
  }

  useEffect(() => {
    if (props.onChange) props.onChange(value.toDate().getTime())
  }, [value])

  useEffect(() => {
    if (props.value) setValue(dayjs(props.value))
  }, [props.value])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimeShow
        disableFuture
        disableOpenPicker
        readOnly
        ampm={false}
        theme={theme}
        value={value}
        slotProps={{ textField: { fullWidth: true } }}
      />
      <WrapPicker>
        <DatePicker
          theme={theme}
          displayStaticWrapperAs='desktop'
          views={['year', 'month', 'day']}
          value={value}
          onChange={(v: any) => {
            setValue(v)
          }}
        />
        <TimePicker theme={theme}>
          <InputLabel shrink htmlFor='select-multiple-native'>
            Time
          </InputLabel>
          <Select
            multiple
            native
            variant='standard'
            value={[value.get('hour')]}
            // @ts-ignore Typings are not considering `native`
            onChange={handleChangeMultiple}
            label='Native'
            inputProps={{
              id: 'select-multiple-native'
            }}
          >
            {Array.from(Array(24).keys()).map(o => {
              return (
                <option key={String(o)} value={String(o)}>
                  {`${String(o).padStart(2, '0')}:00`}
                </option>
              )
            })}
          </Select>
        </TimePicker>
      </WrapPicker>
    </LocalizationProvider>
  )
}
