import { ReactNode } from 'react'
import { deepmerge } from '@mui/utils'
import { Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import { Global as GlobalStyle } from '@emotion/react'
import { useSelector } from 'react-redux'

import { Settings } from '@core/types'
import themeConfig from '@core/configs/themeConfig'
import Direction from '@layouts/components/Direction'
import UserThemeOptions from '@layouts/UserThemeOptions'
import { settingsSelector } from '@store'
import overrides from '@core/theme/overrides'
import typography from '@core/theme/typography'
import GlobalStyling from '@core/theme/globalStyles'

import themeOptions from './ThemeOptions'
import { globalStyle } from './global'

interface Props {
  children: ReactNode
}

const ThemeComponent = (props: Props) => {
  const { children } = props
  const settings = useSelector(settingsSelector.selectSettings)

  const coreThemeConfig = themeOptions(settings)

  let theme = createTheme(coreThemeConfig)

  const mergeComponentOverrides = (themeValue: Theme, settingsValue: Settings) =>
    deepmerge({ ...overrides(themeValue, settingsValue) }, UserThemeOptions()?.components)

  const mergeTypography = (themeValue: Theme) => deepmerge(typography(themeValue), UserThemeOptions()?.typography)

  theme = createTheme(theme, {
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) }
  })

  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme) as any} />
        <GlobalStyle styles={globalStyle(theme)} />
        {children}
      </Direction>
    </ThemeProvider>
  )
}

export default ThemeComponent
