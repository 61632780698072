import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Role } from '@types'

import { State } from './type'

const initialState: State = { roles: [] }

export const slice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    clean: () => {},
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload
    }
  }
})

// > Export
// * Action
export const actions = { ...slice.actions }
// * Reducer
export const { reducer } = slice
