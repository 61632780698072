// > Workspace
export const workspace = {
  WORKSPACE: '/',
  WORKSPACE_OVERVIEW: '/:idProject/overview',
  WORKSPACE_MEASUREMENT: '/:idProject/measurement',
  MODEL: ':idProject/model'
}

const workspaceRouters: Record<keyof typeof workspace, string> = {} as any
Object.keys(workspace).forEach(key => ((workspaceRouters as any)[key] = `/workspace${(workspace as any)[key]}`))

// > Project
export const project = {
  PROJECT: '/',
  PROJECT_LIST: '/list',
  PROJECT_OVERVIEW: '/:idProject/overview',
  PROJECT_CONFIG_LIST: '/:idProject/config/list',
  PROJECT_CONFIG_OVERVIEW: '/:idProject/config/overview',
  PROJECT_MACHINE_OVERVIEW: '/:idProject/machine/overview',
  PROJECT_CONFIG_OVERVIEW_DETAIL: '/:idProject/config/overview/:idSite/detail',
  PROJECT_MACHINE_OVERVIEW_DETAIL: '/:idProject/config/:idSite/machine/overview/:idMachine/detail',
  PROJECT_CONFIG_MACHINE_DETAIL: '/:idProject/config/:idSite/machine/:idMachine/detail',
  PROJECT_CONFIG_MACHINE_LIST: '/:idProject/config/:idSite/machine/list',
  PROJECT_CONFIG_MACHINE_SENSOR_LIST: '/:idProject/site/:idSite/machine/:idMachine/sensor/list'
}

const projectRouters: Record<keyof typeof project, string> = {} as any
Object.keys(project).forEach(key => ((projectRouters as any)[key] = `/project${(project as any)[key]}`))

// > User
// * Profile
export const userProfile = {
  PROFILE: '',
  INFO: '/info',
  SECURITY: '/security',
  BILLING: '/billing'
}
const userProfileRouters: Record<keyof typeof userProfile, string> = {} as any
Object.keys(userProfile).forEach(key => ((userProfileRouters as any)[key] = `/profile${(userProfile as any)[key]}`))

// * Setting
export const accountSettings = {
  ACCOUNT_SETTING: '',
  CHANGE_PASSWORD: '/change-password'
}
const accountSettingsRouters: Record<keyof typeof accountSettings, string> = {} as any
Object.keys(accountSettings).forEach(
  key => ((accountSettingsRouters as any)[key] = `/account-settings${(accountSettings as any)[key]}`)
)

export const user = {
  USER: '/',
  ...accountSettingsRouters,
  ...userProfileRouters
}

// * User routers
const userRouters: Record<keyof typeof user, string> = {} as any
Object.keys(user).forEach(key => ((userRouters as any)[key] = `/user${(user as any)[key]}`))

const mainRouters = {
  LOGIN: '/signin',
  REGISTER: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY: '/verify',
  RESET_PASSWORD: '/resetpwd',
  UNAUTHORIZED: '/401',
  NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
  COMMING_SOON: '/coming-soon',
  UNDER_MAINTENANCE: '/under-maintenance',
  PRIVACY_POLICY: '/privacy-and-policy'
}

export const routers = {
  ...mainRouters,
  ...userRouters,
  ...workspaceRouters,
  ...projectRouters
}

export const routersGuard = [routers.WORKSPACE, routers.PROJECT, routers.USER]
