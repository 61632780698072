import { useState, SyntheticEvent, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'

import { Icon } from '@core/components/custom'
import { Settings } from '@core/types'
import { userSelector } from '@store'
import { useLogout } from '@hooks'
import { routers } from '@defines'
import { capitalize, renderUserName } from '@utils'
import { CustomAvatar } from '@core/components/mui'
import { getInitials } from '@core/utils/get-initials'

interface Props {
  settings: Settings
}

const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const TextName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const UserDropdown = (props: Props) => {
  const { settings } = props
  const { logout } = useLogout()
  const user = useSelector(userSelector.selectData)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const navigate = useNavigate()
  // const { logout } = useAuth()

  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      navigate(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary'
    }
  }

  const handleLogout = async () => {
    handleDropdownClose()
    // const url = `${window.location.pathname}?${queriesToString({ currentQueries: refQueries.current })}`
    await logout()
    navigate(routers.LOGIN)
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {user?.avatar ? (
          <Avatar
            alt={user?.firstName}
            onClick={handleDropdownOpen}
            sx={{ width: 40, height: 40 }}
            src={user?.avatar}
          />
        ) : (
          <CustomAvatar skin='light' color='primary' sx={{ fontSize: '.8rem', width: '2.5rem', height: '2.5rem' }}>
            {getInitials(
              user?.firstName || user?.lastName
                ? user?.firstName.toUpperCase() + ' ' + user?.lastName.toUpperCase()
                : 'Empty Name'
            )}
          </CustomAvatar>
        )}
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              {user?.avatar ? (
                <CustomAvatar src={user?.avatar} sx={{ width: '2.5rem', height: '2.5rem' }} />
              ) : (
                <CustomAvatar
                  skin='light'
                  color='primary'
                  sx={{ fontSize: '.8rem', width: '2.5rem', height: '2.5rem' }}
                >
                  {getInitials(
                    user?.firstName || user?.lastName
                      ? user?.firstName.toUpperCase() + ' ' + user?.lastName.toUpperCase()
                      : 'Empty Name'
                  )}
                </CustomAvatar>
              )}
            </Badge>
            <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Tooltip title={renderUserName(user?.firstName || '', user?.lastName || '')}>
                <TextName sx={{ fontWeight: 600 }}>
                  {renderUserName(user?.firstName || '', user?.lastName || '')}
                </TextName>
              </Tooltip>
              <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {user?.permission && capitalize(user.permission)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: '0 !important' }} />
        <MenuItem sx={{ p: 0 }} onClick={() => navigate(routers.INFO)}>
          {/* <Box sx={styles}>
            <Icon icon='mdi:account-outline' />
            Profile
          </Box> */}
        </MenuItem>
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
          <Box sx={styles}>
            <Icon icon='mdi:email-outline' />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
          <Box sx={styles}>
            <Icon icon='mdi:message-outline' />
            Chat
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        <MenuItem sx={{ p: 0 }} onClick={() => navigate(routers.PROFILE)}>
          <Box sx={styles}>
            <Icon icon='mdi:cog-outline' />
            Settings
          </Box>
        </MenuItem>
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
          <Box sx={styles}>
            <Icon icon='mdi:currency-usd' />
            Pricing
          </Box>
        </MenuItem> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
          <Box sx={styles}>
            <Icon icon='mdi:help-circle-outline' />
            FAQ
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <Icon icon='mdi:logout-variant' />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
