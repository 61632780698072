import { LoadingButton } from '@mui/lab'

export const ButtonSubmit = ({ loading, text }: { loading: boolean; text: string }) => {
  return (
    <LoadingButton
      loading={loading}
      type='submit'
      sx={{ backgroundColor: '#4CAF50', color: '#fff', '&:hover': { color: '#4CAF50' }, minWidth: 128 }}
    >
      {text}
    </LoadingButton>
  )
}
