/*eslint-disable */
export enum RESPONSE_CODE {
  SENT_VERIFICATION_EMAIL_SUCCESS = 20001,
  USER_VERIFIED_SUCCESS = 20002,
  LOGIN_SUCCESS = 20003,
  TOKEN_AUTHORIZED_SUCCESS = 20004,
  LOGOUT_SUCCESS = 20005,
  SIGOUT_OTHER_DEVICES = 20006,
  RESENT_VERIFICATION_EMAIL_SUCCESS = 20007,
  SENT_LINK_FORGOT_PWD_MAIL_SUCCESS = 20008,
  CHANGE_PASSWORD_SUCCESS = 20009,
  RENEW_PASSWORD_SUCCESS = 20010,
  UPDATE_USER_INFO_SUCCESS = 20011,
  ENCRYPTED_PASSWORD_FAIL = 40001,
  CONNECT_GRPC_FAIL = 40002,
  SENT_VERIFICATION_EMAIL_FAIL = 40003,
  CREATE_ACCESS_TOKEN_FAIL = 40004,
  CREATE_REFRESH_TOKEN_FAIL = 40005,
  INVALID_VERIFICATION_CODE = 40006,
  UPDATE_USER_FAIL = 40007,
  VALIDATE_INPUT_DATA_FAIL = 40008,
  INVALID_ACCESS_TOKEN = 40009,
  CREATE_SESSION_LOGIN_FAIL = 40010,
  LOGOUT_FAIL = 40011,
  UPDATE_USER_SESSION_FAIL = 40012,
  SAVE_DATA_TO_DATABASE_FAIL = 40013,
  SENT_LINK_FORGOT_PWD_MAIL_FAIL = 40014,
  SENT_USER_AVATAR_FAIL = 40015,
  CREATE_ROLE_FAIL = 40016,
  GET_PROJECT_FAIL = 40017,
  UPDATE_PROJECT_FAIL = 40018,
  DELETE_PROJECT_FAIL = 40019,
  LINK_VERIFICATION_USER_EXPIRIED = 40101,
  INVALID_CREDENTIALS = 40102,
  USER_UNVERIFIED = 40103,
  TOKEN_EXPIRIED = 40104,
  TOKEN_NOT_VALID_YET = 40105,
  INVALID_CURRENT_PASSWORD = 40106,
  LINK_RENEW_PASSWORD_EXPIRIED = 40107,
  PERMISSION_DENIED = 40300,
  NOT_FOUND = 40400,
  USER_NOT_FOUND_WITH_ID = 40401,
  USER_NOT_FOUND_WITH_EMAIL = 40402,
  USER_NOT_FOUND_WITH_VERIFICATION_CODE = 40403,
  PROJECT_NOT_FOUND = 40404,
  CONFLICT_DATA = 40900,
  USER_EXISTED_WITH_EMAIL = 40901,
  USER_HAS_BEEN_ALREADY_VERIFIED = 40902,
  USER_LOGGED_IN_ANOTHER_DEVICE = 40903,
  INTERNAL_SERVER_ERROR = 50000
}

export const RESPONSE_MESSAGE = {
  [RESPONSE_CODE.SENT_VERIFICATION_EMAIL_SUCCESS]: 'response_code.sent_verification_email_success',
  [RESPONSE_CODE.USER_VERIFIED_SUCCESS]: 'response_code.user_verified_success',
  [RESPONSE_CODE.LOGIN_SUCCESS]: 'response_code.login_success',
  [RESPONSE_CODE.TOKEN_AUTHORIZED_SUCCESS]: 'response_code.token_authorized_success',
  [RESPONSE_CODE.LOGOUT_SUCCESS]: 'response_code.logout_success',
  [RESPONSE_CODE.SIGOUT_OTHER_DEVICES]: 'response_code.sigout_other_devices',

  [RESPONSE_CODE.RESENT_VERIFICATION_EMAIL_SUCCESS]: 'response_code.resent_verification_email_success',
  [RESPONSE_CODE.SENT_LINK_FORGOT_PWD_MAIL_SUCCESS]: 'response_code.sent_link_forgot_pwd_mail_success',
  [RESPONSE_CODE.CHANGE_PASSWORD_SUCCESS]: 'response_code.change_password_success',
  [RESPONSE_CODE.RENEW_PASSWORD_SUCCESS]: 'response_code.renew_password_success',
  [RESPONSE_CODE.UPDATE_USER_INFO_SUCCESS]: 'response_code.update_user_info_success',

  [RESPONSE_CODE.ENCRYPTED_PASSWORD_FAIL]: undefined,
  [RESPONSE_CODE.CONNECT_GRPC_FAIL]: undefined,
  [RESPONSE_CODE.SENT_VERIFICATION_EMAIL_FAIL]: 'response_code.sent_verification_email_fail',
  [RESPONSE_CODE.CREATE_ACCESS_TOKEN_FAIL]: undefined,
  [RESPONSE_CODE.CREATE_REFRESH_TOKEN_FAIL]: undefined,
  [RESPONSE_CODE.INVALID_VERIFICATION_CODE]: 'response_code.invalid_verification_code',
  [RESPONSE_CODE.UPDATE_USER_FAIL]: 'response_code.update_user_fail',
  [RESPONSE_CODE.VALIDATE_INPUT_DATA_FAIL]: 'response_code.validate_input_data_fail',
  [RESPONSE_CODE.INVALID_ACCESS_TOKEN]: undefined,
  [RESPONSE_CODE.CREATE_SESSION_LOGIN_FAIL]: 'response_code.create_session_login_fail',
  [RESPONSE_CODE.LOGOUT_FAIL]: 'response_code.logout_fail',
  [RESPONSE_CODE.UPDATE_USER_SESSION_FAIL]: 'response_code.update_user_session_fail',
  [RESPONSE_CODE.SAVE_DATA_TO_DATABASE_FAIL]: undefined,
  [RESPONSE_CODE.LINK_VERIFICATION_USER_EXPIRIED]: 'response_code.link_verification_user_expiried',
  [RESPONSE_CODE.SENT_USER_AVATAR_FAIL]: 'response_code.sent_user_avatar_fail',
  [RESPONSE_CODE.INVALID_CURRENT_PASSWORD]: 'response_code.invalid_current_password',
  [RESPONSE_CODE.INVALID_CREDENTIALS]: 'response_code.invalid_credentials',
  [RESPONSE_CODE.USER_UNVERIFIED]: 'response_code.user_unverified',
  [RESPONSE_CODE.TOKEN_EXPIRIED]: 'response_code.token_expiried',
  [RESPONSE_CODE.TOKEN_NOT_VALID_YET]: 'response_code.token_not_valid_yet',
  [RESPONSE_CODE.LINK_RENEW_PASSWORD_EXPIRIED]: 'response_code.link_renew_password_expiried',
  [RESPONSE_CODE.USER_NOT_FOUND_WITH_ID]: 'response_code.user_not_found_with_id',
  [RESPONSE_CODE.USER_NOT_FOUND_WITH_EMAIL]: 'response_code.user_not_found_with_email',
  [RESPONSE_CODE.USER_EXISTED_WITH_EMAIL]: 'response_code.user_existed_with_email',
  [RESPONSE_CODE.USER_HAS_BEEN_ALREADY_VERIFIED]: 'response_code.user_has_been_already_verified',
  [RESPONSE_CODE.USER_LOGGED_IN_ANOTHER_DEVICE]: 'response_code.user_logged_in_another_device',
  [RESPONSE_CODE.INTERNAL_SERVER_ERROR]: 'response_code.internal_server_error',
  [RESPONSE_CODE.PERMISSION_DENIED]: 'response_code.permission_denied',
  [RESPONSE_CODE.NOT_FOUND]: 'response_code.not_found',
  [RESPONSE_CODE.CREATE_ROLE_FAIL]: 'response_code.create_role_fail',
  [RESPONSE_CODE.GET_PROJECT_FAIL]: 'response_code.get_project_fail',
  [RESPONSE_CODE.UPDATE_PROJECT_FAIL]: 'response_code.update_project_fail',
  [RESPONSE_CODE.DELETE_PROJECT_FAIL]: 'response_code.delete_project_fail',
  [RESPONSE_CODE.PROJECT_NOT_FOUND]: 'response_code.project_not_found',
  [RESPONSE_CODE.CONFLICT_DATA]: 'response_code.conflict_data'
}
/* eslint-enable */
