/* eslint-disable */
export enum ENVIRONMENT {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

export enum DURATION {
  '1M' = '1m',
  '5M' = '5m',
  '15M' = '15m',
  '1H' = '1h',
  '3H' = '3h',
  '6H' = '6h',
  '12H' = '12h',
  '24H' = '24h',
  '2D' = '2d',
  '7D' = '7d',
  '30D' = '30d'
}

export enum STATUS {
  'MAINTENANCE' = 'maintained',
  'DISCONNECT' = 'disconnect',
  'HEALTHY' = 'healthy',
  'ALARM' = 'alarm',
  'WARNING' = 'warning'
}

export const PAGE = {
  PROJECT: 'project',
  USER: 'user',
  SITE: 'site',
  MACHINE: 'machine'
}

export const DATE_TIME_FORMAT = 'MMMM Do YYYY, HH:mm'

export const DEFAULT_QUERIES = { page: 0, pageSize: 10 }

/* eslint-enable */
