import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { project, routers } from '@defines'
import ProjectLayout from '@layouts/ProjectLayout'
import { appSelector } from '@store'

import { useWorkspacePageRender } from './hooks'

const ProjectListPage = lazy(() => import('@pages/project/list'))
const ProjectOverViewPage = lazy(() => import('@pages/project/overview'))
const SiteListPage = lazy(() => import('@pages/project/overview/components/site/list'))
const MachineListPage = lazy(() => import('@pages/project/overview/components/machine/list'))
const SensorListPage = lazy(() => import('@pages/project/overview/components/sensor/list'))
const SiteOverViewPage = lazy(() => import('@pages/project/overview/components/site/overview'))
const MachineOverViewPage = lazy(() => import('@pages/project/overview/components/machine/overview'))
const SiteOverViewDetail = lazy(() => import('@pages/project/overview/components/site/overview/components/detail'))
const MachineOverViewDetail = lazy(
  () => import('@pages/project/overview/components/machine/overview/components/detail')
)
const MachineDetailPage = lazy(() => import('@pages/project/overview/components/machine/detail'))

// eslint-disable-next-line
const { Router: RouterCore } = process.env.REACT_APP_CORE_ROUTER
  ? require(process.env.REACT_APP_CORE_ROUTER)
  : ({} as any)

export const ProjectRouter: React.FC = () => {
  const isDevelopMode = useSelector(appSelector.isDevelopMode)

  return (
    <ProjectLayout>
      <Routes>
        <Route path={project.PROJECT_LIST} element={useWorkspacePageRender(<ProjectListPage />)} />
        <Route path={project.PROJECT_OVERVIEW} element={useWorkspacePageRender(<ProjectOverViewPage />)} />
        <Route path={project.PROJECT_CONFIG_LIST} element={useWorkspacePageRender(<SiteListPage />)} />
        <Route path={project.PROJECT_CONFIG_MACHINE_LIST} element={useWorkspacePageRender(<MachineListPage />)} />
        <Route path={project.PROJECT_CONFIG_MACHINE_DETAIL} element={useWorkspacePageRender(<MachineDetailPage />)} />
        <Route path={project.PROJECT_CONFIG_MACHINE_SENSOR_LIST} element={useWorkspacePageRender(<SensorListPage />)} />
        <Route path={project.PROJECT_CONFIG_OVERVIEW} element={useWorkspacePageRender(<SiteOverViewPage />)} />
        <Route path={project.PROJECT_CONFIG_OVERVIEW_DETAIL} element={useWorkspacePageRender(<SiteOverViewDetail />)} />
        <Route path={project.PROJECT_MACHINE_OVERVIEW} element={useWorkspacePageRender(<MachineOverViewPage />)} />
        <Route
          path={project.PROJECT_MACHINE_OVERVIEW_DETAIL}
          element={useWorkspacePageRender(<MachineOverViewDetail />)}
        />
        <Route path='/demo/*' element={Boolean(RouterCore) && isDevelopMode ? <RouterCore /> : null} />
        <Route path='*' element={<Navigate to={routers.PROJECT_LIST} replace />} />
      </Routes>
    </ProjectLayout>
  )
}
