import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '@api'
import HeaderBreadcrumbs from '@core/layouts/components/shared-components/HeaderBreadcrumbs'
import { routers } from '@defines'
import { useController } from '@hooks'
import { workspaceSelector } from '@store'
import { MachineModel, ProjectModel, SiteModel } from '@types'

type Href = {
  project: string
  site: string
  machine: string
  machineDetail: string
}

type Props = {
  currentName: string
  hrefs?: Partial<Href>
}

export const ProjectBreadcrumbs: React.FC<Props> = ({ currentName, hrefs }) => {
  const navigate = useNavigate()
  const { controller } = useController()
  const [hrefsList, setHrefsList] = useState<Href>({
    project: routers.PROJECT_LIST,
    site: routers.PROJECT_CONFIG_LIST,
    machine: routers.PROJECT_CONFIG_MACHINE_LIST,
    machineDetail: routers.PROJECT_CONFIG_MACHINE_DETAIL
  })
  const project = useSelector(workspaceSelector.selectProject)
  const site = useSelector(workspaceSelector.selectSite)
  const machine = useSelector(workspaceSelector.selectMachine)

  const fetchProjectList = async (search: URLSearchParams) =>
    controller(async () => {
      const response = await api.getProjects(search)
      return response.data
    })

  const fetchSiteList = async (search: URLSearchParams) =>
    controller(async () => {
      const response = await api.getSites(search, project?.id as string)
      return response.data
    })

  const fetchMachineList = async (search: URLSearchParams) =>
    controller(async () => {
      const response = await api.getMachines(search, project?.id as string, site?.id as string)
      return response.data
    })

  useEffect(() => {
    setHrefsList(s => ({ ...s, ...(hrefs || {}) }))
  }, [])

  return (
    <HeaderBreadcrumbs
      links={[
        {
          name: 'Projects',
          href: routers.PROJECT_LIST
        },
        ...(project
          ? [
              {
                name: project.name,
                href: hrefsList.project.replace(':idProject', project.id as string),
                fetch: fetchProjectList,
                getKey: (item: ProjectModel) => item.id,
                getLabel: (item: ProjectModel) => item.name,
                onClick: (item: ProjectModel) => navigate(hrefsList.site.replace(':idProject', item.id))
              }
            ]
          : []),
        ...(project && site
          ? [
              {
                name: site.name,
                href: hrefsList.site.replace(':idProject', project.id as string).replace(':idSite', site.id as string),
                fetch: fetchSiteList,
                getKey: (item: SiteModel) => item.id,
                getLabel: (item: SiteModel) => item.name,
                onClick: (item: SiteModel) =>
                  navigate(hrefsList.machine.replace(':idProject', project.id as string).replace(':idSite', item.id))
              }
            ]
          : []),
        ...(project && site && machine
          ? [
              {
                name: machine.name,
                href: hrefsList.machine
                  .replace(':idProject', project.id as string)
                  .replace(':idSite', site.id as string)
                  .replace(':idMachine', machine.id as string),
                fetch: fetchMachineList,
                getKey: (item: MachineModel) => item.id,
                getLabel: (item: MachineModel) => item.name,
                onClick: (item: MachineModel) =>
                  navigate(
                    hrefsList.machineDetail
                      .replace(':idProject', project.id)
                      .replace(':idSite', site.id)
                      .replace(':idMachine', item.id)
                  )
              }
            ]
          : []),
        { name: currentName }
      ]}
    />
  )
}
