import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export const SiteNameStyle = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 133.4%;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const BoxStyle = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: '295px',
  padding: '20px',
  boxShadow: '0px 2px 10px rgba(76, 78, 100, 0.22)',
  borderRadius: '10px'
}))

export const MachineNumberStyle = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
`

export const StatusStyle = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  text-transform: capitalize;
`
