import IconButton from '@mui/material/IconButton'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { Icon } from '@core/components/custom'
import { appActions, appSelector } from '@store'
import { ENVIRONMENT } from '@defines'

const DevelopmentToggler = () => {
  const settings = useSelector(appSelector.isDevelopMode)
  const dispatch = useDispatch()

  const handleModeToggle = () => {
    dispatch(appActions.setDevelopMode(!settings))
  }

  return process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.DEVELOPMENT ? (
    <IconButton color='inherit' aria-haspopup='true' onClick={handleModeToggle}>
      <Icon icon={settings === true ? 'material-symbols:logo-dev' : 'material-symbols:logo-dev-outline'} />
    </IconButton>
  ) : null
}

export default DevelopmentToggler
