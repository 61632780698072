import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loading } from '@components'
import { routers } from '@defines'
import { userSelector } from '@store'

export const EmptyRouter: React.FC = () => {
  const navigate = useNavigate()
  const userData = useSelector(userSelector.selectData)

  useEffect(() => {
    if (!userData) {
      navigate(routers.LOGIN, { replace: true })
    } else {
      navigate(routers.PROJECT, { replace: true })
    }
  }, [userData])

  return <Loading />
}
